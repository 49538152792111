import type { ComponentPublicInstance } from "vue";

import type { ErrorHandlingResult } from "../types";

export class ApplicationError extends Error {
  public sourceComponent: ComponentPublicInstance | null;
  public sourceError: Error;
  public handlingResult: ErrorHandlingResult;

  public get message(): string {
    return this.sourceError.message;
  }

  public constructor(
    sourceComponent: ComponentPublicInstance | null,
    sourceError: Error,
  ) {
    super(sourceError.message);
    this.stack = sourceError.stack;
    this.sourceComponent = sourceComponent;
    this.sourceError = sourceError;

    const augmentedError = sourceError as Error & {
      handlingResult: ErrorHandlingResult;
    };
    this.handlingResult = augmentedError.handlingResult
      ? augmentedError.handlingResult
      : {
          handled: false,
          propagate: true,
        };
  }
}
