export const bidsRoutes = [
  {
    path: "/bids",
    name: "BidListPage",
    component: () => import("../bids/views/bidListPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
];
