export const pickListsRoutes = [
  {
    path: "/pick-pack-ship",
    name: "PickPackShip",
    component: () => import("./views/PickPackShipBase.vue"),
    redirect: { name: "PickLists.Regular" },
    children: [
      {
        path: "",
        name: "PickLists.Regular",
        component: () => import("./views/RegularPickListPage.vue"),
      },
      {
        path: "bundles",
        name: "PickLists.Bundles",
        component: () => import("./views/BundlePickListPage.vue"),
      },
      {
        path: "specials",
        name: "PickLists.Specials",
        component: () => import("./views/SpecialsPickListPage.vue"),
      },
      {
        path: "exports",
        name: "PickLists.Exports",
        component: () => import("./views/ExportsPickListPage.vue"),
      },
      {
        path: "longstorage",
        name: "PickLists.LongStorage",
        component: () => import("./views/ToLongStoragePickListPage.vue"),
      },
      {
        path: "framethegame",
        name: "PickLists.FTG",
        component: () => import("./views/FTGPickListPage.vue"),
      },
      {
        path: "all",
        name: "PickLists.AllProducts",
        component: () => import("./views/AllProductsPickListPage.vue"),
      },
    ],
  },
];
