export const sportsRoutes = [
  {
    path: "/sports",
    name: "SportsListPage",
    component: () => import("../sports/views/SportsListPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
];
