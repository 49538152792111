import { defineStore } from "pinia";

import type { PaginatedResult } from "@/common/types";
import { searchProductListings } from "@/products/api/services";
import type { AdminProductSku, ProductSku } from "@/products/types";

export const useProductListingStore = defineStore({
  id: "product-listings",
  state: (): {
    productListings: Record<ProductSku["id"], ProductSku>;
  } => ({
    productListings: {}, // Pool of product listings. The components that will display or use sweepstakes will get them from here
  }),
  getters: {},
  actions: {
    async search(
      searchTerm: string,
      pageSize = 25,
      offset = 0,
    ): Promise<PaginatedResult<AdminProductSku>> {
      const response = await searchProductListings(
        searchTerm,
        pageSize,
        offset,
      );

      response.results.forEach((productListing) => {
        this.productListings[productListing.id] = productListing;
      });

      return response;
    },
  },
});
