export const parcelTypeRoutes = [
  {
    path: "/parcel-types",
    name: "ParcelTypeListPage",
    component: () => import("./views/ParcelTypeListPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
  {
    path: "/parcel-types/:parcelTypeId",
    name: "ParcelTypeDetailsPage",
    component: () => import("./views/ParcelTypeDetailsPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
];
