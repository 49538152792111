import de_DE from "../lang/transifex/de_DE.json";
import en_GB from "../lang/transifex/en_GB.json";
import pt_BR from "../lang/transifex/pt_BR.json";
import pt_PT from "../lang/transifex/pt_PT.json";

export const messages = {
  en: en_GB,
  de: de_DE,
  pt_PT: pt_PT,
  pt_BR: pt_BR,
};

const specialLocales = new Set(["pt_PT", "pt_BR"]);
export const localeMapper = (locale: string) => {
  const convertedLocale = locale.replace("-", "_");
  if (specialLocales.has(convertedLocale)) {
    return convertedLocale;
  }

  const normalizedLocale = convertedLocale.split("_")[0];
  if (normalizedLocale === "pt") {
    return "pt_PT";
  }

  return normalizedLocale;
};
