import type { App } from "vue";

import ErrorBoundaryComponent from "./components/ErrorBoundary.vue";
import ErrorTextComponent from "./components/ErrorText.vue";
import ErrorSnackbarComponent from "./components/ErrorToast.vue";
import ErrorValidationComponent from "./components/ErrorValidation.vue";

export default {
  install: (app: App) => {
    app.component("ErrorBoundary", ErrorBoundaryComponent);
    app.component("ErrorText", ErrorTextComponent);
    app.component("ErrorToast", ErrorSnackbarComponent);
    app.component("ErrorValidation", ErrorValidationComponent);
  },
};
