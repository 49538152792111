export const teamRoutes = [
  {
    path: "/teams",
    name: "TeamsListPage",
    component: () => import("../teams/views/TeamsListPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
  {
    path: "/teams/:teamId",
    name: "TeamDetailsPage",
    component: () => import("../teams/views/TeamDetailsBase.vue"),
    meta: {
      auth: ["Admin.Access"],
    },
    redirect: { name: "TeamDetails.Details" },
    children: [
      {
        path: "",
        name: "TeamDetails.Details",
        component: () => import("../teams/views/TeamDetails.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "external-provider-records",
        name: "TeamDetails.ExternalProviderRecords",
        component: () =>
          import("../teams/views/TeamDetailsExternalProviderRecords.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },

      {
        path: "events",
        name: "TeamDetails.Events",
        component: () => import("../teams/views/TeamDetailsEvents.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "rosters",
        name: "TeamDetails.Rosters",
        component: () => import("../teams/views/TeamDetailsRosters.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "kits",
        name: "TeamDetails.Kits",
        component: () => import("../teams/views/TeamKit.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
    ],
  },
];
