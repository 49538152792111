import deburr from "lodash/deburr";
import { inflate } from "pako";

export function shortenString(sentence?: string | null, n = 15) {
  if (!sentence) return;

  if (sentence.length > n) {
    return `${sentence.substring(0, n)}...`;
  } else {
    return sentence;
  }
}

/**
 * Use this function to convert a gzipped string into a decompressed string
 * This is not a replacement for content encoding header.
 * @param gzippedString
 */
export function getUngzippedString(gzippedString: string) {
  // Decode base64 (convert ascii to binary)
  const binaryData = window.atob(gzippedString);

  // Convert binary string to number array containing character codes
  const binData = Uint8Array.from(
    binaryData.split("").map((x) => x.charCodeAt(0)),
  );

  // Pako magic
  const decompressedData = inflate(binData);

  // Convert gunzipped byteArray back to ascii string:
  return String.fromCharCode.apply(null, Array.from(decompressedData));
}

export function searchIgnoringAccents(text: string, searchTerm: string) {
  return deburr(text).toLowerCase().includes(deburr(searchTerm).toLowerCase());
}

/**
 * Helper function to convert a snake_case string to camelCase.
 */
export function snakeToCamel(s: string): string {
  return s.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
}

/**
 * Helper function to convert a camelCase string to snake_case.
 */
export function camelToSnake(s: string): string {
  return s.replace(/([A-Z])/g, "_$1").toLowerCase();
}
