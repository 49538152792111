<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  noPadding?: boolean;
  bodyClass?: string;
}>();

const addedBodyClass = computed(() =>
  props.noPadding ? "" : "px-4 py-5 sm:p-6",
);
</script>

<template>
  <div class="base-card rounded-lg bg-white shadow">
    <div :class="[bodyClass ?? '', addedBodyClass]">
      <slot />
    </div>
  </div>
</template>
