export const framingFeatureOptionRoutes = [
  {
    path: "/framing/feature-options",
    name: "FramingFeatureOptionsListPage",
    component: () => import("./views/FeatureOptionsListPage.vue"),
    meta: {
      auth: ["Developer.Access"],
    },
  },
  {
    path: "/framing/feature-options/:featureType/:optionId",
    name: "FramingFeatureOptionDetailsPage",
    component: () =>
      import("@/framing/featureOptions/views/FeatureOptionDetailsPage.vue"),
    meta: {
      auth: ["Developer.Access"],
    },
    children: [
      {
        path: "",
        name: "FeatureOptionDetailPage.Details",
        component: () =>
          import("@/framing/featureOptions/components/FeatureOptionDetail.vue"),
        meta: {
          auth: {
            roles: ["Developer.Access"],
          },
        },
      },
      {
        path: "prices-ledger",
        name: "FeatureOptionDetailPage.PricesLedger",
        component: () =>
          import(
            "@/framing/featureOptions/components/FeatureOptionPriceLedger.vue"
          ),
        meta: {
          auth: {
            roles: ["Developer.Access"],
          },
        },
      },
    ],
  },
];
