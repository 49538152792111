export const vendorRoutes = [
  {
    path: "/vendors",
    name: "VendorsListPage",
    component: () => import("./views/VendorsListPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
  {
    path: "/vendors/:vendorId",
    name: "VendorDetailsPage",
    component: () => import("./views/VendorDetailsBase.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
    redirect: { name: "VendorDetails.Details" },
    children: [
      {
        path: "",
        name: "VendorDetails.Details",
        component: () => import("./views/VendorDetails.vue"),
        meta: {
          auth: {
            roles: ["Admin.Access"],
          },
        },
      },
      {
        path: "contracts",
        name: "VendorDetails.Contracts",
        component: () => import("./views/VendorDetailsContractsList.vue"),
        meta: {
          auth: {
            roles: ["Admin.Access"],
          },
        },
      },
      {
        path: "vendor-invoices",
        name: "VendorDetails.VendorInvoices",
        component: () => import("./views/VendorDetailsVendorInvoicesList.vue"),
        meta: {
          auth: {
            roles: ["Admin.Access"],
          },
        },
      },
      {
        path: "customer-invoices",
        name: "VendorDetails.CustomerInvoices",
        component: () =>
          import("./views/VendorDetailsCustomerInvoicesList.vue"),
        meta: {
          auth: {
            roles: ["Admin.Access"],
          },
        },
      },
    ],
  },
];
