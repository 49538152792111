import { createMessage, type FormKitNode } from "@formkit/core";
import cloneDeep from "lodash/cloneDeep";
import isObject from "lodash/isObject";
import { nextTick, onMounted } from "vue";
import { useI18n } from "vue-i18n";

import { getDifference } from "@/common/utils/getDifferenceBetweenObjects";
import { isFormKitNode } from "@/plugins/utils/isFormKitNode";

import { useFormKitErrorHandler } from "./errors/composables/useFormKitErrorHandler";

export const addUnsavedChangesMessagePlugin = (node: FormKitNode) => {
  const messageKey = "unsavedChanges";
  const { t } = useI18n();

  node.on("created", () => {
    if (node.props.type !== "form") return;

    if (node.props.attrs["disable-unsaved-changes-message"]) return;

    node.props.initialValue = cloneDeep(node.value);

    node.on("commit", () => {
      if (!node.context) throw new Error("missing node context");

      if (!node.props.definition)
        throw new Error("FormKit node definition was undefined");

      const isDirty = node.context.state.dirty;
      if (!isDirty) return;

      if (!isObject(node.props.initialValue) || !isObject(node.value)) return;

      const unsavedFieldNames = Object.keys(
        getDifference(node.props.initialValue, node.value),
      );

      if (unsavedFieldNames.length === 0) {
        node.store.remove(messageKey);
      } else {
        const message = createMessage({
          blocking: false,
          key: messageKey,
          meta: {},
          type: "help",
          value: t("error.unsavedChanges"),
          visible: true,
        });

        node.store.set(message);
      }

      // make the border color indigo for input fields that have unsaved changes
      node.children.filter((childNode) => {
        if (!isFormKitNode(childNode) || !childNode.context) return;

        if (unsavedFieldNames.includes(childNode.name)) {
          childNode.context.classes.inner += " border-indigo-300";
        } else {
          childNode.context.classes.inner =
            childNode.context.classes.inner?.replace(
              " border-indigo-300",
              "",
            ) ?? "";
        }
      });
    });

    node.on("reset", ({ payload }) => {
      node.props.initialValue = cloneDeep(payload.value);
      node.store.remove(messageKey);
    });

    // clean the message when the form is submitted
    node.hook.submit((value, next) => {
      node.props.initialValue = cloneDeep(node.value);
      node.store.remove(messageKey);

      return next(value);
    });

    return;
  });
};

/**
 * This is a plugin which disables the form and shows a message accoringly if the prop `user-is-write-authorized` is `false`.
 * Mainly to be used with user permissions.
 *
 * example usage:
 * ```
 * <FormKitForm :user-can-write="userHasPermission('Customer.Write')">
 *  <!-- some form inputs -->
 * </FormKitForm>
 * ```
 */
export const addFormAuthorizationPlugin = (node: FormKitNode) => {
  node.on("created", () => {
    if (node.props.type !== "form") return;
    node.addProps(["userCanWrite"]);

    const isAuthorized = node.props.userCanWrite;
    if (isAuthorized !== false) return;

    node.props.disabled = true;

    const message = createMessage({
      blocking: true,
      key: "unauthorizedMessage",
      meta: {},
      type: "warning",
      value: "You are not authorized to make changes",
      visible: true,
    });

    node.store.set(message);
  });
};

/**
 * A little plugin that automatically scrolls to the first error.
 *
 * Based on the example here: https://formkit.com/essentials/examples#plugins#plugins
 **/
export function scrollToErrors(node: FormKitNode) {
  if (node.props.type === "form") {
    const scrollTo = (node: FormKitNode) => {
      if (!node.props.id) return;

      const el = document.getElementById(node.props.id);
      el?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    };

    const scrollToErrors = () => {
      node.walk((child) => {
        // Check if this child has errors
        const hasErrorMessage = Object.values(child.store).some(
          (message) => message.type === "error",
        );

        const hasBlockingMessage =
          child.ledger.value("blocking") || child.ledger.value("errors");

        if (hasErrorMessage || hasBlockingMessage) {
          // We found an input with validation errors
          scrollTo(child);

          // Stop searching
          return false;
        }
      }, true);
    };

    const onSubmitInvalid = node.props.onSubmitInvalid;
    node.props.onSubmitInvalid = () => {
      onSubmitInvalid?.(node);
      scrollToErrors();
    };

    node.on("unsettled:errors", () => {
      // it takes some time for the errors to be set in the node store after a failing network request
      nextTick().then(() => {
        scrollToErrors();
      });
    });
  }

  return false;
}

/**
 * Removes the `[object Promise]` that appears on angular to vue transitions
 */
export const removeNonResolvedIconsPlugin = (node: FormKitNode) => {
  node.on("created", () => {
    if (node.type !== "input" || !node.context) return;

    onMounted(() => {
      const formkitIconEl = document.querySelector(
        `#${node.context?.id} + .formkit-icon`,
      );

      if (!formkitIconEl) return;

      if (formkitIconEl.innerHTML === "[object Promise]") {
        formkitIconEl.remove();
      }
    });
  });
};

export const trimPlugin = (node: FormKitNode) => {
  node.hook.submit((payload, next) => {
    const newPayload = cloneDeep(payload);
    for (const prop in newPayload) {
      newPayload[prop] =
        typeof newPayload[prop] === "string"
          ? newPayload[prop].trim()
          : newPayload[prop];
    }

    return next(newPayload);
  });
};

export const clearErrorsOnSubmitPlugin = (node: FormKitNode) => {
  node.hook.submit((payload, next) => {
    node.clearErrors();

    return next(payload);
  });
};

export const validationErrorsPlugin = (node: FormKitNode) => {
  if (!node.parent && node.context?.type === "form") {
    node.on("created", () => {
      useFormKitErrorHandler(node);
    });
  }
};

/**
 * This plugin will disable the node if the initial value of the node is `true`.
 */
export const makeDisabledIfInitialValueIsTrue = (node: FormKitNode) => {
  node.on("created", () => {
    node.props.disabled = node.props.disabled || node.value === true;
  });
};
