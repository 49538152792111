<script setup lang="ts">
import type { FormKitFrameworkContext } from "@formkit/core";
import { mergeAttributes } from "@tiptap/core";
import BulletList from "@tiptap/extension-bullet-list";
import BaseHeading from "@tiptap/extension-heading";
import Link from "@tiptap/extension-link";
import OrderedList from "@tiptap/extension-ordered-list";
import Placeholder from "@tiptap/extension-placeholder";
import TextAlign from "@tiptap/extension-text-align";
import { StarterKit } from "@tiptap/starter-kit";
import { EditorContent, useEditor } from "@tiptap/vue-3";

const props = defineProps<{
  context: FormKitFrameworkContext;
}>();

// Adding inline styles to the html created by the editor so that the styles are preserved on the Marketplace
type Levels = 1 | 2 | 3;
const styles: Record<Levels, string> = {
  1: "font-size: 3em; font-weight: bold;",
  2: "font-size: 2em;",
  3: "font-size: 1.5em;",
};

const customHeading = BaseHeading.configure({ levels: [1, 2, 3] }).extend({
  renderHTML({ node, HTMLAttributes }) {
    const hasLevel = this.options.levels.includes(node.attrs.level);
    const level: Levels = hasLevel ? node.attrs.level : this.options.levels[0];

    return [
      `h${level}`,
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        style: `${styles[level]}`,
      }),
      0,
    ];
  },
});

const customBulletList = BulletList.configure().extend({
  renderHTML() {
    return [
      "ul",
      mergeAttributes(this.options.HTMLAttributes, {
        style:
          "list-style: circle; padding-left: 2rem; padding-top: 2rem; padding-bottom: 2rem;",
      }),
      0,
    ];
  },
});

const customOrderedList = OrderedList.configure().extend({
  renderHTML() {
    return [
      "ol",
      mergeAttributes(this.options.HTMLAttributes, {
        style:
          "list-style: lower-roman; padding-left: 2rem; padding-top: 2rem; padding-bottom: 2rem;",
      }),
      0,
    ];
  },
});

// For image uploading
// https://gist.github.com/slava-vishnyakov/16076dff1a77ddaca93c4bccd4ec4521

// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const editor = useEditor({
  extensions: [
    StarterKit.configure({
      // we will manually add the following extensions
      heading: false,
      bulletList: false,
      orderedList: false,
    }),
    customHeading,
    customBulletList,
    customOrderedList,
    Placeholder,
    TextAlign.configure({ types: ["heading", "paragraph"] }),
    Link.configure({
      HTMLAttributes: {
        class: "underline cursor-pointer",
      },
    }),
  ],
  content: props.context._value,
  onUpdate({ editor }) {
    props.context.node.input(editor.getHTML());
  },
});

const setLink = (e: Event) => {
  e.preventDefault();

  const previousUrl = editor.value?.getAttributes("link").href;
  const url = window.prompt("URL", previousUrl);

  // cancelled
  if (url === null) {
    return;
  }

  // empty
  if (url === "") {
    editor.value?.chain().focus().extendMarkRange("link").unsetLink().run();

    return;
  }

  // update link
  editor.value
    ?.chain()
    .focus()
    .extendMarkRange("link")
    .setLink({ href: url, target: "_blank" })
    .run();
};

const unsetLink = (e: Event) => {
  e.preventDefault();
  editor.value?.chain().focus().unsetLink().run();
};
</script>

<template>
  <div class="editor-wrapper">
    <div
      v-if="editor"
      class="editor"
    >
      <div class="buttons">
        <button
          type="button"
          :class="{ 'is-active': editor.isActive('bold') }"
          @click="editor?.chain().focus().toggleBold().run()"
        >
          bold
        </button>
        <button
          type="button"
          :class="{ 'is-active': editor.isActive('italic') }"
          @click="editor?.chain().focus().toggleItalic().run()"
        >
          italic
        </button>
        <button
          type="button"
          :class="{ 'is-active': editor.isActive('strike') }"
          @click="editor?.chain().focus().toggleStrike().run()"
        >
          strike
        </button>
        <button
          type="button"
          :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
          @click="editor?.chain().focus().toggleHeading({ level: 1 }).run()"
        >
          h1
        </button>
        <button
          type="button"
          :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
          @click="editor?.chain().focus().toggleHeading({ level: 2 }).run()"
        >
          h2
        </button>
        <button
          type="button"
          :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
          @click="editor?.chain().focus().toggleHeading({ level: 3 }).run()"
        >
          h3
        </button>
        <button
          type="button"
          :class="{ 'is-active': editor.isActive('bulletList') }"
          @click="editor?.chain().focus().toggleBulletList().run()"
        >
          bullet list
        </button>
        <button
          type="button"
          :class="{ 'is-active': editor.isActive('orderedList') }"
          @click="editor?.chain().focus().toggleOrderedList().run()"
        >
          ordered list
        </button>
        <button
          :class="{ 'is-active': editor.isActive('link') }"
          @click="setLink"
        >
          set link
        </button>
        <button
          :disabled="!editor.isActive('link')"
          @click="unsetLink"
        >
          unset link
        </button>
        <button @click.prevent="editor?.chain().focus().setHardBreak().run()">
          hard break
        </button>
        <button
          type="button"
          @click="editor?.chain().focus().undo().run()"
        >
          undo
        </button>
        <button
          type="button"
          @click="editor?.chain().focus().redo().run()"
        >
          redo
        </button>
      </div>
    </div>
    <EditorContent
      class="inner-editor"
      :editor="editor"
    />
  </div>
</template>

<style lang="postcss">
.ProseMirror {
  overflow: auto;
  margin-top: 1rem;

  &:focus {
    outline: none;
  }
}
</style>

<style lang="postcss" scoped>
/* Basic editor styles */
.ProseMirror {
  :focus {
    outline: none;
  }
}
button,
input,
select {
  font-size: inherit;
  font-family: inherit;
  color: black;
  margin: 0.1rem;
  border: 1px solid black;
  border-radius: 0.3rem;
  padding: 0.1rem 0.4rem;
  background: white;
  accent-color: black;

  &[disabled] {
    opacity: 0.3;
  }
}
.is-active {
  background: black;
  color: white;
}

.editor-wrapper {
  @apply p-4;
}
</style>
