<script setup lang="ts">
import { XMarkIcon } from "@heroicons/vue/20/solid";
import { MapPinIcon } from "@heroicons/vue/24/outline";
import type { AdminProductSkuDto } from "mws-catalog-ts-sdk";
import { computed, onUnmounted, toRefs, watch } from "vue";

import CardVue from "@/common/components/card/CardVue.vue";
import LoadingSpinner from "@/common/components/LoadingSpinner.vue";
import { useGetProductQuery } from "@/products/products/api/queries";

import { useGetProductListingByIdQuery } from "../api/queries";

const props = defineProps<{
  productListingId: string;
  openLinkInNewTab: boolean;
  showCross: boolean;
  innerClass: string;
}>();

const emit = defineEmits<{
  (e: "link-clicked"): void;
  (e: "cross-clicked"): void;
  (e: "product-listing-fetched", productListing: AdminProductSkuDto): void;
  (e: "product-listing-removed", productListing: AdminProductSkuDto): void;
}>();

const { productListingId } = toRefs(props);
const { data: productListing, isPending } =
  useGetProductListingByIdQuery(productListingId);

const productId = computed(() => productListing.value?.productId);
const { data: product, isPending: isProductPending } = useGetProductQuery(
  productId,
  {
    enabled: () => !!productId.value,
  },
);

watch(
  productListing,
  (newValue) => {
    if (newValue) {
      emit("product-listing-fetched", newValue);
    }
  },
  { immediate: true },
);

onUnmounted(() => {
  if (productListing.value) {
    emit("product-listing-removed", productListing.value);
  }
});
</script>

<template>
  <CardVue
    class="h-full flex"
    body-class="w-full"
  >
    <LoadingSpinner v-if="isPending || isProductPending" />

    <div
      v-else-if="productListing"
      class="flex justify-between items-center flex-1 h-full"
    >
      <div
        class="flex items-center justify-start gap-4 flex-1 relative w-full"
        :class="innerClass"
      >
        <img
          v-if="productListing.media.filter((m) => m.visibility)[0]?.url"
          :src="productListing.media.filter((m) => m.visibility)[0]?.url"
          class="min-w-1/4 max-w-32 max-h-full aspect-square rounded-full object-cover border border-gray-200"
        />

        <div class="flex flex-col items-start justify-between">
          <RouterLinkVue
            class="font-bold"
            :to="`/products/${productListing.productId}`"
            :target="openLinkInNewTab ? '_blank' : '_self'"
            @click="emit('link-clicked')"
          >
            {{ productListing.displayName }}
          </RouterLinkVue>

          <span
            v-if="productListing?.productDetails.Match"
            class="text-gray-500 text-sm"
          >
            {{ productListing?.productDetails.Match }}
          </span>

          <span class="text-gray-500 -ml-1 mt-1">
            <MapPinIcon class="size-5 inline mb-1" />
            <span class="ml-0.5">
              {{ product?.location.locationString }}
            </span>
          </span>
        </div>
      </div>

      <button
        v-if="showCross"
        class="p-4 pr-0 cursor-pointer group justify-self-end"
        @click="emit('cross-clicked')"
      >
        <XMarkIcon
          class="size-4 text-gray-500 group-hover:text-gray-700 group-active:text-gray-900"
        />
      </button>
    </div>
  </CardVue>
</template>
