export const productLabelRoutes = [
  {
    path: "/labels",
    name: "ProductLabelListPage",
    component: () => import("./views/ProductLabelListPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
  {
    path: "/labels/:labelId",
    name: "ProductLabelDetailsPage",
    component: () => import("./views/ProductLabelDetailsPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
  {
    path: "/labels/create",
    name: "CreateLabelPage",
    component: () => import("./views/CreateLabelPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
];
