export const fabricksChipsRoutes = [
  {
    path: "/fabricks/chips",
    name: "ChipsListPage",
    component: () => import("../chips/views/ChipsListPage.vue"),
    meta: {
      auth: {
        roles: ["FabricksAdmin.Access"],
      },
    },
  },
  {
    path: "/fabricks/chips/:chipId",
    name: "FabricksChipDetailsPage",
    component: () => import("../chips/views/ChipDetailsPage.vue"),
    meta: {
      auth: {
        roles: ["FabricksAdmin.Access"],
      },
    },
    children: [
      {
        path: "/fabricks/chips/:chipId",
        name: "FabricksChipDetailsPage.Details",
        component: () => import("../chips/views/ChipDetails.vue"),
        meta: {
          auth: ["FabricksAdmin.Access"],
        },
      },
      {
        path: "/fabricks/chips/:chipId/taps",
        name: "FabricksChipDetailsPage.Taps",
        component: () => import("../chips/views/ChipTaps.vue"),
        meta: {
          auth: ["FabricksAdmin.Access"],
        },
      },
      {
        path: "/fabricks/chips/:chipId/logs",
        name: "FabricksChipDetailsPage.NfcCommandLogs",
        component: () => import("../chips/views/ChipNfcCommandLogs.vue"),
        meta: {
          auth: ["FabricksAdmin.Access"],
        },
      },
    ],
  },
  {
    path: "/fabricks/chip-scans",
    name: "ChipScanPage",
    component: () => import("../chips/views/ChipScanPage.vue"),
    meta: {
      auth: {
        roles: ["FabricksAdmin.Access"],
      },
    },
  },
];
