<script setup lang="ts">
import { watch } from "vue";
import { useToast } from "vue-toastification";

import { useErrorComponent } from "../composables";
import type { ErrorComponentProps } from "../types";

const toast = useToast();

const props = withDefaults(defineProps<ToastErrorProps>(), {
  timeout: 10000,
  propagate: true,
  handled: false,
});
const { currentError } = useErrorComponent(props);

watch(currentError, (error) => {
  toast.error(error?.message ?? "", { timeout: props.timeout });
});
</script>

<script lang="ts">
export type ToastErrorProps = {
  timeout?: number;
} & ErrorComponentProps;
</script>

<template>
  <span />
</template>
