export const shipmentRoutes = [
  {
    path: "/global-shipping-configs",
    name: "GlobalShippingConfigsListPage",
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
    children: [
      {
        path: "",
        name: "GlobalShippingConfigsListPage",
        component: () => import("./views/GlobalShippingConfigsListPage.vue"),
        meta: {
          auth: {
            roles: ["Admin.Access"],
          },
        },
      },

      {
        path: ":globalShippingConfigId",
        name: "GlobalShippingConfigsDetailsPage",
        component: () => import("./views/GlobalShippingConfigsDetailsPage.vue"),
        meta: {
          auth: {
            roles: ["Admin.Access"],
          },
        },
      },
    ],
  },
];
