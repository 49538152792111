<script setup lang="ts">
import type { FormKitFrameworkContext } from "@formkit/core";
import { XMarkIcon } from "@heroicons/vue/24/solid";
import { computed, nextTick, onMounted, ref, watch } from "vue";

import BadgeVue from "@/common/components/BadgeVue.vue";

const props = withDefaults(
  defineProps<{
    context: FormKitFrameworkContext;
    options?: string[];
  }>(),
  {
    options: () => [],
  },
);

// Tags
const tags = ref<string[] | null>(null);
watch(
  () => props.context._value,
  (newValue) => (tags.value = newValue),
  { deep: true, immediate: true },
);
const newTag = ref("");
const id = Math.random().toString(36).substring(7);

const addTag = (tag: string) => {
  if (!tag) return; // prevent empty tag

  if (!tags.value) tags.value = [];

  // return early if duplicate
  if (tags.value.includes(tag)) {
    handleDuplicate(tag);

    return;
  }
  tags.value.push(tag);
  newTag.value = ""; // reset newTag
};

const removeTag = (index: number | null) => {
  if (!tags.value || index === null) {
    throw new Error("Tags/index is null while trying to remove tag");
  }

  tags.value.splice(index, 1);
};

// handling duplicates
const duplicate = ref("");
const handleDuplicate = (tag: string) => {
  duplicate.value = tag;
  setTimeout(() => (duplicate.value = ""), 1000);
  newTag.value = "";
};

// positioning and handling tag change
const onTagsChange = () => {
  props.context.node.input(tags.value);
  // emit("update:modelValue", tags.value);
};
watch(tags, () => nextTick(onTagsChange), { deep: true });
onMounted(onTagsChange);

// options
const availableOptions = computed(() => {
  if (props.options.length === 0) return [];

  return props.options.filter((option) => !tags.value?.includes(option));
});
</script>

<template>
  <input
    v-model="newTag"
    :class="context.classes.input"
    type="text"
    :list="id"
    autocomplete="off"
    @keydown.prevent.enter="addTag(newTag)"
    @keydown.prevent.tab="addTag(newTag)"
    @keydown.delete="
      (newTag.length && tags) || removeTag(tags && tags.length - 1)
    "
  />

  <datalist
    v-if="options.length > 0"
    :id="id"
  >
    <option
      v-for="option in availableOptions"
      :key="option"
      :value="option"
    >
      {{ option }}
    </option>
  </datalist>

  <ul class="tags mt-1 flex flex-wrap gap-1">
    <li
      v-for="(tag, index) in tags"
      :key="tag"
      class="tag"
      :class="{ duplicate: tag === duplicate }"
    >
      <BadgeVue :class="context.classes.chip">
        {{ tag }}
        <button
          type="button"
          class="delete ml-1"
          @click="removeTag(index)"
        >
          <XMarkIcon class="size-4" />
        </button>
      </BadgeVue>
    </li>
  </ul>
</template>
