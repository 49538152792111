import { type ClassConstructor, plainToInstance } from "class-transformer";

import type { UnknownObject } from "@/common/types";
/**
 * Converts plain (literal) object to class (constructor) object. Also works with arrays.
 * This method is to be used to create instances of classes from plain objects we receive from the BE instead of using constructors.
 *
 * This method assumes that the UnknownObject received from the BE is of the correct type, with the correct fields.
 * It is theoretically possible that the class instance returned from this method does not correctly reflect the fields in the class.
 *
 * Example:
 *  const event = createInstance(Event, {});
 *  console.log(event.name); // ts compiler will not complain even though `name` is not set
 */
export default <T>(
  classConstructor: ClassConstructor<T>,
  obj: UnknownObject,
) => {
  return plainToInstance(classConstructor, obj);
};
