import axios, { type AxiosRequestConfig } from "axios";
import type { AthleteDto } from "mws-sports-ts-sdk";
import type { Ref } from "vue";

import {
  Method,
  type PaginatedResult,
  type UnknownObject,
} from "@/common/types";
import createInstance from "@/common/utils/createInstance";
import { AdminProductSku, ProductEvent } from "@/products/types";

const catalogApiUrl = import.meta.env.VITE_CATALOG_API;

export const getEventsByProductListingIds = async (
  skuIds: Ref<string[] | undefined>,
) => {
  const axiosConfig: AxiosRequestConfig = {
    method: "get",
    url: `${catalogApiUrl}/admin/products/events`,
    params: {
      skuIds: skuIds.value?.join(","),
    },
  };
  const response = await axios.request<UnknownObject[]>(axiosConfig);

  return response.data.map((event) => createInstance(ProductEvent, event));
};

export const getProductsByAthlete = async (athleteId: AthleteDto["id"]) => {
  const axiosConfig: AxiosRequestConfig = {
    method: Method.GET,
    url: `${catalogApiUrl}/admin/products/athletes/${athleteId}`,
  };

  const response = await axios.request<AthleteDto>(axiosConfig);

  return response.data;
};

export const searchProductListings = async (
  searchTerm: string,
  pageSize = 5,
  offset = 0,
) => {
  const config: AxiosRequestConfig = {
    method: "get",
    url: `${catalogApiUrl}/admin/products/search`,
    params: {
      searchTerm,
      pageSize,
      offset,
    },
  };

  const response = await axios.request<PaginatedResult<UnknownObject>>(config);

  const newProductListings = response.data.results.map((productListing) =>
    createInstance(AdminProductSku, productListing),
  );

  return { ...response.data, results: newProductListings };
};
