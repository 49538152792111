import axios, {
  type AxiosRequestConfig,
  type AxiosRequestHeaders,
} from "axios";

import { getSafeFileName } from "@/common/utils/fileUtils";

export const downloadExternalFile = ({
  filename,
  uri,
  blob,
  skipAuthorization,
}: {
  filename: string;
  uri?: string;
  blob?: Blob;
  skipAuthorization?: boolean;
}) => {
  if (uri) {
    filename = getSafeFileName(filename, uri);
  }

  if (blob) {
    return downloadBlob(filename, blob);
  }

  const request: AxiosRequestConfig = {
    url: uri,
    method: "GET",
    responseType: "blob",
  };

  if (skipAuthorization) {
    request.transformRequest = (data, headers) => {
      if (headers) {
        delete headers.Authorization;
        if (headers.common)
          delete (headers.common as unknown as AxiosRequestHeaders)
            .Authorization;
      }

      return data;
    };
  }

  axios(request as AxiosRequestConfig<unknown>).then((response) => {
    downloadBlob(filename, new Blob([response.data]));
  });
};

export const downloadBlob = (filename: string, blob: Blob) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
