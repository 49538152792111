<script setup lang="ts">
// Source: https://vite-pwa-org.netlify.app/frameworks/vue.html#prompt-for-update
import { useRegisterSW } from "virtual:pwa-register/vue";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

async function checkForUpdates(r: ServiceWorkerRegistration) {
  try {
    if (!!r.installing || !navigator || !("connection" in navigator)) {
      return;
    }

    const response = navigator.onLine
      ? await fetch("/sw.js", {
          cache: "no-store",
          headers: {
            "cache-control": "no-cache",
          },
        })
      : null;

    response && response.status === 200 && (await r.update());
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("cannot ping/update sw.js", e);
  }
}

const intervalMS = 10 * 60 * 1000; // 10 minutes
const { needRefresh, updateServiceWorker } = useRegisterSW({
  immediate: true,
  onRegistered(r) {
    if (r) {
      checkForUpdates(r);
      setInterval(checkForUpdates, intervalMS);
    }
  },
});

async function close() {
  needRefresh.value = false;
}

const reload = async () => {
  localStorage.setItem("isAppUpdated", "true");
  await updateServiceWorker();
};

const router = useRouter();
router.beforeEach(async (_, __, next) => {
  if (needRefresh.value) {
    await reload();
  } else {
    next();
  }
});

// some logic to show a message when the app has been updated
const showAppUpdatedMessage = ref(false);
onMounted(() => {
  if (localStorage.getItem("isAppUpdated")) {
    localStorage.removeItem("isAppUpdated");

    showAppUpdatedMessage.value = true;
    setTimeout(() => {
      showAppUpdatedMessage.value = false;
    }, 3000);
  }
});
</script>

<template>
  <Transition>
    <div
      v-if="needRefresh"
      class="fixed right-0 bottom-0 m-4 p-4 bg-white border border-gray-200 rounded-lg shadow z-50 max-w-80"
      role="alert"
    >
      <div class="mb-4">
        <span>
          New content available, click on the reload button to update, otherwise
          you may not have access to the latest changes and fixes.
        </span>
      </div>
      <div class="flex justify-end space-x-2">
        <button
          class="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md"
          @click="reload"
        >
          Reload
        </button>
        <button
          class="px-4 py-2 text-sm font-medium text-gray-800 border-2 border-indigo-600 rounded-md"
          @click="close"
        >
          Close
        </button>
      </div>
    </div>
    <div v-else-if="showAppUpdatedMessage">
      <div
        class="fixed right-0 bottom-0 m-4 p-4 bg-white border border-gray-200 rounded-lg shadow z-50 max-w-80"
        role="alert"
      >
        The app has been updated with the latest changes and fixes 👨‍🔧
      </div>
    </div>
  </Transition>
</template>

<style scoped lang="postcss">
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
