import { Type } from "class-transformer";
import { AuthenticityMethod, ProductUnpublishReason } from "mws-catalog-ts-sdk";

import type { Beneficiary } from "@/beneficiaries/types";
import type {
  Amount,
  CatalogShippingType,
  Guid,
  Label,
  MediaContent,
  Visibility,
} from "@/common/types";
import type { FramingStatus } from "@/framing/types";
import type { Team } from "@/teams/types";

export class ProductSku {
  readonly id!: Guid;
  readonly slug!: string;
  readonly eventId!: Guid;
  readonly productTypeId!: Guid;
  readonly price!: Amount;
  readonly quickBids!: Amount[] | null;
  readonly displayName!: string | null;
  readonly labels!: Label[];
  readonly media!: MediaContent[];
  readonly finished!: boolean;
  readonly priorityScore!: number;
  readonly certificateNumber!: string | null;
  readonly partnerLegalName!: string | null;
  readonly partnerId!: Guid | null;
  readonly formalProductDescription!: string | null;
  readonly beneficiaryPercentage!: number | null;
  readonly showBeneficiaryPercentage!: boolean;
  @Type(() => Date)
  readonly startDate!: Date;
  @Type(() => Date)
  readonly endDate!: Date;

  public getAdminPageLink(): string {
    return `/product-listings/${this.id}`;
  }
}

export class AdminProductSku extends ProductSku {
  readonly sweepstakeId!: Guid; // non-nullable, if the product does not belong to a sweepstake, it will be a "0000..." Guid
  readonly details!: Record<string, string> | null;
  readonly beneficiary!: Beneficiary | null;
  readonly productCategory!: ProductListingCategory;
  readonly framingStatus!: FramingStatus;
  readonly shippingType!: CatalogShippingType;
  readonly athleteId!: Guid;
  readonly shippingOfficeId!: Guid;
  readonly contractId!: Guid;
  readonly createdBy!: Guid;
  readonly modifiedBy!: Guid;
  readonly stock!: number;
  readonly collectionIds!: Guid[] | null;
  readonly visibility!: Visibility;
  @Type(() => Date)
  readonly dateCreated!: Date;
  @Type(() => Date)
  readonly dateModified!: Date;
  readonly countryOfOrigin!: string | null;
  readonly fabricksTrackableProductId!: Guid | null;
  readonly teamId!: Guid | null;
}

export enum ProductListingCategory {
  Normal = "normal",
  Special = "special",
}

export enum SalesMethod {
  SWEEPSTAKES = "sweepstakes",
  BIDDING = "bidding",
  FIXEDPRICE = "fixedPrice",
}

export const unpublishReasonOptions: Array<{
  label: string;
  value: ProductUnpublishReason | null;
}> = [
  { label: "Not Received", value: ProductUnpublishReason.NotReceived },
  { label: "Duplicate", value: ProductUnpublishReason.Duplicated },
  { label: "Cancelled", value: ProductUnpublishReason.Cancelled },
];

export type DefaultProductProperties = {
  salesMethod: SalesMethod;
  basePriceInEurosCents: number;
  stock: number;
  defaultLabels: string[] | null;
  shippingType: CatalogShippingType | null;
  authenticityMethod: AuthenticityMethod;
  starterProductTypeId: string | null;
  substituteProductTypeId: string | null;
  benchProductTypeId: string | null;
};

export class ProductEvent {
  readonly skuId!: string;
  readonly eventId!: string;
  readonly startDate!: Date;
  readonly endDate!: Date;
  readonly featuredTeams!: Team[];
}
