<script lang="ts" setup>
import { onErrorCaptured } from "vue";

import { useErrorBoundary } from "../composables";

const { handleError } = useErrorBoundary();

/**
 * Called when an error from any descendent component is captured.
 * The hook receives three arguments: the error, the component instance that triggered the error,
 * and a string containing information on where the error was captured. The hook can return false to
 * stop the error from propagating further.
 */
onErrorCaptured((err, instance) => {
  // Formkit Date Picker throws this error with brazilian date format, suppress it for now.
  if (err.message === "Invalid offset format") return;

  return handleError(err, instance);
});
</script>

<template>
  <slot />
</template>
