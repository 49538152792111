import { defineStore } from "pinia";

import type { PaginatedResult } from "@/common/types";
import { searchEvents } from "@/events/api/services";
import type { MarketplaceEvent } from "@/events/types";

export const useEventStore = defineStore({
  id: "events",
  state: (): {
    events: Record<MarketplaceEvent["id"], MarketplaceEvent>;
  } => ({
    events: {}, // Pool of events. The components that will display or use sweepstakes will get them from here
  }),
  getters: {},
  actions: {
    async search(
      searchTerm: string,
      pageSize = 25,
      offset = 0,
    ): Promise<PaginatedResult<MarketplaceEvent>> {
      const response = await searchEvents(searchTerm, pageSize, offset);

      response.results.forEach((event) => {
        this.events[event.id] = event;
      });

      return response;
    },
  },
});
