<template>
  <ErrorBoundary>
    <FormKit
      v-slot="formkitSlotData"
      type="form"
      v-bind="$attrs"
    >
      <slot
        name="default"
        v-bind="formkitSlotData"
      />
      <slot name="actions" />
    </FormKit>
  </ErrorBoundary>
</template>
