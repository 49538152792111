<script setup lang="ts">
import {
  ArrowLeftStartOnRectangleIcon,
  Bars3BottomLeftIcon,
} from "@heroicons/vue/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/vue/24/solid";
import { useMagicKeys, whenever } from "@vueuse/core";
import { useAuth } from "@websanova/vue-auth";
import { computed } from "vue";

import { useCurrentUser } from "@/users/public/composables";

const emit = defineEmits<{
  (e: "open-sidebar-click"): void;
  (e: "open-search-click"): void;
}>();

const { logout } = useCurrentUser();

// if OS is mac, shortcut is CMD + K, otherwise CTRL + K
const isMac = computed(
  () => navigator.userAgent.toLowerCase().indexOf("mac") > 0,
);

const environment = import.meta.env.MODE.toUpperCase();

const keys = useMagicKeys({
  passive: false,
  onEventFired(e) {
    if (e.type !== "keydown") return;

    if (e.key !== "k") return;

    if ((isMac.value && e.metaKey) || (!isMac.value && e.ctrlKey)) {
      e.preventDefault();

      return;
    }
  },
});

const cmdK = keys["Cmd+K"];
const ctrlK = keys["Ctrl+K"];
const shortCutTriggered = computed(() =>
  isMac.value ? cmdK?.value : ctrlK?.value,
);
whenever(shortCutTriggered, () => {
  emit("open-search-click");
});

const auth = useAuth();
const user = auth.user();
</script>

<template>
  <div class="menu-topbar__container">
    <button
      type="button"
      class="menu-topbar__open-sidebar-button"
      @click="$emit('open-sidebar-click')"
    >
      <span class="sr-only">Open sidebar</span>
      <Bars3BottomLeftIcon
        class="menu-topbar__menu-icon"
        aria-hidden="true"
      />
    </button>

    <div class="menu-topbar__right-side">
      <div class="menu-topbar__right-side__content">
        <div class="menu-topbar__right-side__search-container">
          <button
            class="menu-topbar__right-side__search__button group"
            @click="$emit('open-search-click')"
          >
            <MagnifyingGlassIcon
              class="menu-topbar__right-side__search__icon"
              aria-hidden="true"
            />
            <span class="menu-topbar__right-side__search__text">
              Search events, products and customers
            </span>
          </button>
        </div>
        <div class="menu-topbar__utilities">
          <div
            class="hidden lg:flex px-2 whitespace-nowrap text-sm text-gray-600 font-medium"
          >
            {{ environment }}
          </div>
          <div
            v-if="user"
            class="hidden lg:flex px-2 whitespace-nowrap text-sm text-gray-600 font-medium"
          >
            {{ user.email }}
          </div>
        </div>
      </div>
    </div>
    <ArrowLeftStartOnRectangleIcon
      class="menu-topbar__utilities__logout-button"
      label="Logout"
      @click="logout"
    />
  </div>
</template>

<style scoped lang="postcss">
.menu-topbar {
  &__container {
    @apply sticky top-0 z-30 flex-shrink-0 flex gap-4 h-16 px-4 py-2 bg-white border-b border-gray-200 items-center;
  }

  &__open-sidebar-button {
    @apply border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500;
    @apply md:hidden;
  }

  &__menu-icon {
    @apply h-6 w-6;
  }

  &__right-side {
    @apply flex-1 flex justify-between items-center;
    @apply min-w-0;

    &__content {
      @apply flex-1 flex gap-x-4 items-center;
      @apply min-w-0;
    }

    &__search-container {
      @apply flex-grow flex-shrink;
      @apply relative h-full flex items-center;
      @apply min-w-0;
    }

    &__search {
      &__button {
        @apply w-full text-gray-400 border;
        @apply hover:text-gray-500 hover:border-gray-300;
        @apply relative py-2 px-2 sm:px-4 rounded flex items-center gap-x-2;
        @apply min-w-0;
      }

      &__icon {
        @apply h-5 w-5 flex-shrink-0;
      }

      &__text {
        @apply text-sm;
        @apply truncate whitespace-nowrap overflow-hidden;
        @apply min-w-0;
      }
    }
  }

  &__utilities {
    @apply flex items-center gap-x-2 md:gap-x-4 flex-shrink-0;

    &__logout-button {
      @apply cursor-pointer rounded-sm size-6 text-gray-500;
      @apply hover:text-gray-400;
    }
  }
}
</style>
