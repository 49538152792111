export const productListingRoutes = [
  {
    path: "/product-listings",
    name: "ProductListingsListPage",
    component: () => import("./views/ProductListingsListPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
  {
    path: "/product-listings/:productListingId/configuration-templates/:templateId",
    name: "ProductListingDetails.ConfigurationTemplateDetail",
    component: () =>
      import(
        "@/framing/configurations/views/ConfigurationTemplateDetailPage.vue"
      ),
    children: [
      {
        path: "",
        name: "ProductListingDetails.ConfigurationTemplateDetails.Details",
        component: () =>
          import(
            "@/framing/configurations/views/ConfigurationTemplateDetail.vue"
          ),
        meta: {
          auth: ["FramingAdmin.Access"],
        },
      },
      {
        path: "assets",
        name: "ProductListingDetails.ConfigurationTemplateDetails.Assets",
        component: () =>
          import("@/framing/configurations/views/ConfigurationAssets.vue"),
        meta: {
          auth: ["FramingAdmin.Access"],
        },
      },
      {
        path: "configurator",
        name: "ProductListingDetails.ConfigurationTemplateDetails.Configurator",
        component: () =>
          import(
            "@/framing/configurations/views/ConfigurationTemplateConfigurator.vue"
          ),
        meta: {
          auth: ["FramingAdmin.Access"],
        },
      },
    ],
    meta: {
      auth: ["Admin.Access"],
    },
  },
  {
    path: "/product-listings/:productListingId",
    name: "ProductListingDetailsPage",
    component: () =>
      import("@/products/productListings/views/ProductListingDetailsPage.vue"),
    // children are used to define the content of the tabs
    children: [
      {
        path: "",
        name: "ProductListingDetails.Overview",
        component: () =>
          import("@/products/productListings/views/ProductListingOverview.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "product",
        name: "ProductListingDetails.Product",
        component: () =>
          import("@/products/productListings/views/ProductListingProduct.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "bids",
        name: "ProductListingDetails.Bids",
        component: () =>
          import("@/products/productListings/views/ProductListingBids.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "configuration-templates",
        name: "ProductListingDetails.ConfigurationTemplates",
        component: () =>
          import("./views/ProductListingConfigurationTemplates.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "price",
        name: "ProductListingDetails.Price",
        component: () =>
          import("@/products/productListings/views/ProductListingPrice.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "categories",
        name: "ProductListingDetails.Categories",
        component: () =>
          import(
            "@/products/productListings/views/ProductListingCategories.vue"
          ),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "framing-details",
        name: "ProductListingDetails.FramingDetails",
        component: () =>
          import("@/framing/views/ProductListingFramingDetails.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "notes",
        name: "ProductListingDetails.Notes",
        component: () =>
          import(
            "@/products/productListings/views/ProductListingNotesPage.vue"
          ),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "finance",
        name: "ProductListingDetails.Finance",
        component: () =>
          import("@/products/productListings/views/ProductListingFinance.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "related",
        name: "ProductListingDetails.Related",
        component: () =>
          import("@/products/productListings/views/ProductListingRelated.vue"),
        meta: {
          auth: {
            roles: ["Admin.Access"],
          },
        },
      },
    ],
  },
  {
    path: "/product-listings/create",
    name: "ProductListingCreatePage",
    component: () =>
      import("@/products/productListings/views/ProductListingCreatePage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
];
