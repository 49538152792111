export const platformRoutes = [
  {
    path: "/platforms",
    name: "PlatformsListPage",
    component: () => import("./views/PlatformsListPage.vue"),
    meta: {
      auth: ["Admin.Access"],
    },
  },
  {
    path: "/platforms/:platformId",
    name: "PlatformDetailsPage",
    component: () => import("./views/PlatformDetailsBase.vue"),
    meta: {
      auth: ["Admin.Access"],
    },
    children: [
      {
        path: "",
        name: "PlatformDetailsPage.Details",
        component: () => import("./views/PlatformDetailsPage.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "domains",
        name: "PlatformDetailsPage.Domains",
        component: () => import("./views/PlatformDomainsPage.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "redirects",
        name: "PlatformDetailsPage.Redirects",
        component: () => import("./views/PlatformRedirectsPage.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "email-settings",
        name: "PlatformDetailsPage.EmailSettings",
        component: () => import("./views/PlatformEmailSettingsPage.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
    ],
  },
  {
    path: "/platforms/:platformId/configurations/:configurationId",
    name: "PlatformConfigurationPage",
    component: () => import("./views/PlatformConfigurationPage.vue"),
    meta: {
      auth: ["Admin.Access"],
    },
  },
];
