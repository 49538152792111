<script setup lang="ts">
import type { MarketplaceEvent } from "@/events/types";

defineProps<{
  marketplaceEvent: MarketplaceEvent;
  isActive?: boolean;
}>();

const getReadableDate = (date: Date) => {
  return date.toLocaleDateString(undefined, {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};
</script>

<template>
  <div class="event-list-item__container">
    <span>{{ marketplaceEvent.name }}</span>
    <span
      class="event-list-item__dates"
      :class="{ 'event-list-item__dates--active': isActive }"
    >
      {{ getReadableDate(marketplaceEvent.startDate) }}
      -
      {{ getReadableDate(marketplaceEvent.endDate) }}
    </span>
  </div>
</template>

<style scoped lang="postcss">
.event-list-item {
  &__container {
    @apply flex justify-between py-2;
  }

  &__dates {
    @apply text-gray-400 text-right;

    &--active {
      @apply text-gray-100;
    }
  }
}
</style>
