/* types related to the Identity service */

/* atoms */
export type AuthResponse = {
  accessToken: string;
  refreshToken: string;
  refreshTokenExpiresUtc: string;
};

export enum UserGroup {
  Temporary = "temporary",
  Permanent = "permanent",
  Verified = "verified",
  Suspended = "suspended",
  BidBlocked = "bid-blocked",
  Anonymous = "anonymous",
}

export enum VerificationName {
  EmailVerified = "emailVerified",
  PhoneVerified = "phoneVerified",
  AddressVerified = "addressVerified",
  PaymentVerified = "paymentVerified",
}

export enum VerificationStatusType {
  New = "new",
  Pending = "pending",
  Done = "done",
}

export enum UpdateUserVerificationGroup {
  Suspended = "suspended",
  Unsuspended = "unsuspended",
  BidBlocked = "bid-block",
  BidUnblock = "bid-unblock",
}

/* molecules */

export type Verification = {
  name: VerificationName;
  status: VerificationStatusType;
};

/* organisms */

export type VerificationStatus = {
  currentTier: UserGroup;
  verifications: Verification[];
  requiredVerificationGroups: string[][];
};

export type Token = {
  accessToken: string;
  refreshToken: string;
};
