import { AxiosError } from "axios";
import {
  type ComponentPublicInstance,
  getCurrentInstance,
  inject,
  type InjectionKey,
  onBeforeMount,
  provide,
  ref,
} from "vue";

import { ApplicationError, HttpError, ValidationError } from "../models";
import type {
  ErrorComponentProps,
  ErrorHandlerCallback,
  ErrorHandlingResult,
  ValidationFailure,
} from "../types";

const registerErrorHandlerInjectionKey: InjectionKey<
  (callback: ErrorHandlerCallback) => void
> = Symbol("registerErrorHandler");

type AugmentedHandlingResult = unknown & {
  handlingResult: ErrorHandlingResult;
};

type ValidationFailureResponse = { failures: ValidationFailure[] };

export const useErrorBoundary = () => {
  const errorHandlers: Array<{
    handler: ErrorHandlerCallback;
    instance: ComponentPublicInstance | null;
  }> = [];

  const registerErrorHandler = (callback: ErrorHandlerCallback): void => {
    const instance = getCurrentInstance()?.proxy ?? null;
    errorHandlers.push({ handler: callback, instance });
  };

  provide(registerErrorHandlerInjectionKey, registerErrorHandler);

  /**
   * Wraps an error within an ApplicationError-derived error instance that carries extra
   * information for use in the error handling infrastructure.
   *
   * @param sourceError The source error to wrap
   */
  const wrapError = (
    sourceError: unknown,
    instance: ComponentPublicInstance | null,
  ) => {
    // 1. Axios error - http request failure
    if (sourceError instanceof AxiosError) {
      // 1a. If it's a validation failure we call the special validation failure handlers:
      if (
        sourceError.response &&
        sourceError.response.data &&
        (sourceError.response.data as ValidationFailureResponse).failures
      ) {
        return new ValidationError(
          instance,
          sourceError as AxiosError,
          sourceError.response.status,
          sourceError.response.data.failures,
        );
      }

      // 1b. Treat it as a generic http error
      return new HttpError(
        instance,
        sourceError as Error,
        sourceError.response?.status ?? 500,
      );
    }

    // Push a generic error
    return new ApplicationError(instance, sourceError as Error);
  };

  const handleError = (
    sourceError: unknown,
    instance: ComponentPublicInstance | null,
  ): boolean | void => {
    const error = wrapError(sourceError, instance);

    for (const errorHandlerContainer of errorHandlers) {
      const result = errorHandlerContainer.handler(error);
      if (typeof result === "boolean") {
        error.handlingResult.propagate = error.handlingResult.propagate
          ? result
          : false;
        error.handlingResult.handled = error.handlingResult.handled
          ? true
          : result;
      } else {
        error.handlingResult.propagate = error.handlingResult.propagate
          ? result.propagate
          : false;
        error.handlingResult.handled = error.handlingResult.handled
          ? true
          : result.handled;
      }
    }

    (sourceError as AugmentedHandlingResult).handlingResult =
      error.handlingResult;

    return error.handlingResult.propagate;
  };

  return {
    handleError,
  };
};

export const useErrorComponent = (props: ErrorComponentProps) => {
  const registerErrorHandler = inject(registerErrorHandlerInjectionKey);

  const onErrorOccurred = (
    error: ApplicationError,
  ): boolean | ErrorHandlingResult => {
    currentError.value = error;

    return {
      handled: error.handlingResult.handled || (props.handled ?? false),
      propagate: props.propagate ?? true,
    };
  };

  const currentError = ref<ApplicationError | null>(null);

  onBeforeMount(() => {
    if (registerErrorHandler) {
      registerErrorHandler(onErrorOccurred);
    }
  });

  return {
    registerErrorHandler,
    currentError,
  };
};
