// TODO replace loose equality operators with more explicit type-safe equality, i.e. '==='
/* eslint-disable eqeqeq */
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import transform from "lodash/transform";

import type { UnknownObject } from "@/common/types";

/**
 * This function compares the fields of two objects iteratively and returns true if the values are loose-equal.
 *
 * ex:
 * ```
 * const obj1 = { a: 1, b: 2, c: { d: 4 } };
 * const obj2 = { a: '1', b: '2', c: { d: '4' } };
 *
 * isDeepLooseEqual(obj1, obj2) // true
 * ```
 */
export function isDeepLooseEqual(value: unknown, otherValue: unknown): boolean {
  if (value == otherValue) return true;

  if (value === null || otherValue === null) return false;

  if (typeof value !== "object" || typeof otherValue !== "object") return false;

  const objKeys = Object.keys(value);
  const otherObjKeys = Object.keys(otherValue);

  if (objKeys.length !== otherObjKeys.length) return false;

  for (const key of objKeys) {
    const val = (value as UnknownObject)[key];
    const otherVal = (otherValue as UnknownObject)[key];

    const areObjects = isObject(val) && isObject(otherVal);
    if (
      (areObjects && !isDeepLooseEqual(val, otherVal)) ||
      (!areObjects && val != otherVal)
    ) {
      return false;
    }
  }

  return true;
}

/**
 * Find difference between two objects
 * This function uses loose equality to check for differences
 * If the objects are equal, this function going to return an empty object
 * @param  origObj - Source object to compare newObj against
 * @param  newObj  - New object with potential changes
 * @return differences
 */
export function getDifference(origObj: unknown, newObj: unknown) {
  function changes(newObj: unknown, origObj: unknown) {
    if (!isObject(newObj) || !isObject(origObj)) {
      throw new Error("Both arguments must be objects");
    }

    let arrayIndexCounter = 0;

    return transform(newObj, (result: UnknownObject, value, key) => {
      if (!isDeepLooseEqual(value, origObj[key])) {
        const resultKey = isArray(origObj) ? arrayIndexCounter++ : key;

        result[resultKey] =
          isObject(value) && isObject(origObj[key])
            ? changes(value, origObj[key])
            : value;
      }
    });
  }

  return changes(newObj, origObj);
}
