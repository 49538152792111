<script setup lang="ts">
import type { ProductSku } from "@/products/types";

defineProps<{
  productListing: ProductSku;
  isActive?: boolean;
}>();

const getReadableDate = (date: Date) => {
  return date.toLocaleDateString(undefined, {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};
</script>

<template>
  <div class="product-listing-list-item__container">
    <span>{{ productListing.displayName }}</span>

    <span
      class="product-listing-list-item__dates"
      :class="{ 'product-listing-list-item__dates--active': isActive }"
    >
      {{ getReadableDate(productListing.startDate) }}
      -
      {{ getReadableDate(productListing.endDate) }}
    </span>
  </div>
</template>

<style scoped lang="postcss">
.product-listing-list-item {
  &__container {
    @apply flex justify-between items-center py-2;
  }

  &__dates {
    @apply text-gray-400 text-right;
    &--active {
      @apply text-gray-100;
    }
  }
}
</style>
