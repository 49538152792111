export const framingOfferRoutes = [
  {
    path: "/framing/offers",
    name: "FramingOfferListPage",
    component: () => import("./views/OfferListPage.vue"),
    meta: {
      auth: ["FramingAdmin.Access", "FramingOffers.Read"],
    },
  },
  {
    path: "/framing/offers/:offerId",
    name: "OfferDetails",
    component: () => import("../offers/views/OfferDetailsBase.vue"),
    meta: {
      auth: {
        roles: ["FramingAdmin.Access", "FramingOffers.Read"],
      },
    },
    children: [
      {
        path: "",
        name: "OfferDetails.Details",
        component: () => import("./views/OfferDetails.vue"),
        meta: {
          auth: {
            roles: ["FramingAdmin.Access", "FramingOffers.Read"],
          },
        },
      },
      {
        path: "configurations",
        name: "OfferDetails.Configurations",
        component: () => import("./views/OfferConfigurationList.vue"),
        meta: {
          auth: {
            roles: ["FramingAdmin.Access", "FramingOffers.Read"],
          },
        },
      },
      {
        path: "assets",
        name: "OfferDetails.Assets",
        component: () => import("./views/OfferAssets.vue"),
        meta: {
          auth: {
            roles: ["FramingAdmin.Access", "FramingOffers.Read"],
          },
        },
      },
    ],
  },
];
