<script setup lang="ts">
import { useToast } from "vue-toastification";

import { useErrorComponent } from "../composables";
import { ValidationError } from "../models";
import type { ErrorComponentProps } from "../types";
import { createValidationErrorToastRenderFn } from "../utils";

const toast = useToast();

const props = withDefaults(defineProps<ValidationErrorProps>(), {
  timeout: 10000,
  propagate: true,
  handled: false,
  showFailures: false,
});
const { registerErrorHandler } = useErrorComponent(props);

if (registerErrorHandler) {
  registerErrorHandler((error) => {
    if (error instanceof ValidationError) {
      toast.error(
        {
          render: createValidationErrorToastRenderFn(error, props.showFailures),
        },
        { timeout: props.timeout },
      );

      return false;
    }

    // The vue3-popper library throws a lot of errors that are not relevant to the user
    // and are not actionable. We want to ignore these errors.
    // TODO remove this check when we can replace vue3-popper with a different library
    // or native poppers
    if (error.stack?.includes("popper")) {
      return false;
    }

    return true;
  });
}
</script>

<script lang="ts">
export type ValidationErrorProps = {
  timeout?: number;
  showFailures?: boolean;
} & ErrorComponentProps;
</script>

<template>
  <span />
</template>
