import type { VueAuth } from "@websanova/vue-auth";
import type { UserDetailsItemDto } from "mws-identity-ts-sdk";
import { computed, inject } from "vue";

import { REFRESH_TOKEN_STORAGE_KEY } from "@/common/utils/constants";
import cognito from "@/misc/drivers/cognito";
import type { Permission } from "@/permissions/types";

/**
 * This is a composable to conveniently check the information of the currently logged in user.
 * It also provides utilities to check the specific permissions they have.
 *
 * example usage:
 * ```
 * <script setup lang="ts">
 *    const { userHasPermission } = useCurrentUser();
 *    const userHasPermissionEditProducts: boolean = userHasPermission('Product.Write');
 *    // ...do something with this information
 * </script>
 * ```
 */
export const useCurrentUser = function () {
  const $auth = inject<VueAuth>("$auth");

  const user = computed(
    () =>
      $auth?.user() as
        | (UserDetailsItemDto & { permissions: Permission[] }) // permissions injected by the `parseUserData` in src/plugins/auth.ts
        | undefined,
  );

  const usersPermissions = computed(() => user.value?.permissions);

  const userHasPermission = (targetPermissions: Permission | Permission[]) => {
    if (Array.isArray(targetPermissions)) {
      return targetPermissions.every((target) =>
        usersPermissions.value?.includes(target),
      );
    }

    return usersPermissions.value?.includes(targetPermissions);
  };

  const logout = () => {
    let refreshToken;
    try {
      refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }

    $auth?.logout({
      makeRequest: true,
      data: { token: refreshToken, client_id: cognito.params.client_id },
      redirect: "/login",
    });
    localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
  };

  return {
    $auth,
    user,
    usersPermissions,
    userHasPermission,
    logout,
  };
};
