/**
 * This file includes constants that are to be used in multiple places in the project
 */

export const PLATFORM_STORAGE_KEY = "X-Platform-Key" as const;
export const ACCESS_TOKEN_KEY = "access_token" as const;
export const REFRESH_TOKEN_KEY = "refresh_token" as const;
export const ACCESS_TOKEN_STORAGE_KEY = "accessToken" as const;
export const REFRESH_TOKEN_STORAGE_KEY = "refreshToken" as const;

export const WHITE_MWS_LOGO_URL =
  "https://matchwornshirt.imgix.net/assets/MWS_Logo_Wordmark_Master_White.svg" as const;

export const BLACK_MWS_LOGO_URL =
  "https://matchwornshirt.imgix.net/assets/MWS_Logo_Wordmark_Master_Black.svg" as const;

export const BLACK_FABRICKS_LOGO =
  "https://matchwornshirt.imgix.net/fabricks_logo_black.png" as const;

export const MOCKUP_IMAGE_LABELS = [
  "FrontWithSleevesImage",
  "FrontNoSleevesImage",
  "BackWithSleevesImage",
  "BackNoSleevesImage",
] as const;

export const EURO_CODE = "EUR" as const;

export const ELASTIC_SEARCH_WAIT_TIME = 1000 as const;

export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000" as const;
