export const athleteV2Routes = [
  {
    path: "/athletes-v2",
    name: "AthletesListPageV2",
    component: () => import("./views/AthletesListPage.vue"),

    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
  {
    path: "/athletes-v2/:athleteId",
    name: "AthleteDetailsV2",
    component: () => import("./views/AthleteDetailsBase.vue"),
    meta: {
      auth: ["Admin.Access"],
    },
    redirect: { name: "AthleteDetails.Details" },
    children: [
      {
        path: "",
        name: "AthleteDetailsV2.Details",
        component: () => import("./views/AthleteDetails.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
    ],
  },
];
