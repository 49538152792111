import axios from "axios";
import { defineStore } from "pinia";

import { PLATFORM_STORAGE_KEY } from "@/common/utils/constants";

export const usePlatformStore = defineStore({
  id: "platform",
  state: (): {
    platformKey: string;
  } => ({
    platformKey: "",
  }),
  getters: {},
  actions: {
    setPlatformKey(platformKey: string) {
      this.platformKey = platformKey;
      axios.defaults.headers.common[PLATFORM_STORAGE_KEY] = this.platformKey;
      localStorage.setItem(PLATFORM_STORAGE_KEY, platformKey);
    },
  },
});
