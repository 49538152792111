export const categoryRoutes = [
  {
    path: "/categories",
    name: "CategoriesPageBase",
    component: () => import("../categories/views/CategoriesPageBase.vue"),
    meta: {
      auth: ["Categories.Read"],
    },
    children: [
      {
        path: "",
        name: "CategoriesPage",
        component: () => import("./views/CategoriesPage.vue"),
        meta: {
          auth: ["Categories.Read"],
        },
      },
      {
        path: "menu-hierarchies",
        name: "MenuHierarchiesPage",
        component: () =>
          import("../menuHierarchies/views/MenuHierarchiesPage.vue"),
        meta: {
          auth: ["Categories.Read"],
        },
        children: [
          {
            path: ":menuHierarchyId",
            name: "MenuHierarchiesDetails",
            component: () =>
              import("../menuHierarchies/views/MenuHierarchiesPage.vue"),
            meta: {
              auth: ["Categories.Read"],
            },
          },
        ],
      },
    ],
  },
  {
    path: "/categories/:categoryId",
    name: "CategoryDetails",
    component: () => import("../categories/views/CategoryDetailsPageBase.vue"),
    meta: {
      auth: ["Categories.Read"],
    },
    redirect: { name: "CategoryDetails.Details" },
    children: [
      {
        path: "",
        name: "CategoryDetails.Details",
        component: () => import("../categories/views/CategoryDetailsPage.vue"),
        meta: {
          auth: ["Categories.Read"],
        },
      },
      {
        path: "events",
        name: "CategoryDetails.Events",
        component: () => import("../categories/views/CategoryEventsPage.vue"),
        meta: {
          auth: ["Categories.Read"],
        },
      },
    ],
  },
  {
    path: "/admin-tools",
    name: "AdminTools",
    component: () => import("../adminTools/views/AdminToolsPage.vue"),
  },
];
