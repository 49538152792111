export const fabricksPartnersRoutes = [
  {
    path: "/fabricks/partners",
    name: "FabricksPartnersListPage",
    component: () => import("../partners/views/PartnersListPage.vue"),
    meta: {
      auth: {
        roles: ["FabricksAdmin.Access"],
      },
    },
  },
  {
    path: "/fabricks/partners/:partnerId",
    name: "FabricksPartnerDetailsPage",
    component: () => import("../partners/views/PartnerDetailsBase.vue"),
    meta: {
      auth: {
        roles: ["FabricksAdmin.Access"],
      },
    },
    children: [
      {
        path: "",
        name: "FabricksPartnerDetailsPage.Details",
        component: () => import("../partners/views/PartnerDetails.vue"),
        meta: {
          auth: ["FabricksAdmin.Access"],
        },
      },
    ],
  },
];
