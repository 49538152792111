export const kitsRoutes = [
  {
    path: "/teams/:teamId/kits/create",
    name: "CreateKitPage",
    component: () => import("./views/CreateKitPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
  {
    path: "/teams/:teamId/kits/:kitId",
    name: "KitDetailsPage",
    component: () => import("./views/KitDetailsPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
];
