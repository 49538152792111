export const productTypeRoutes = [
  {
    path: "/product-types",
    name: "ProductTypeListPage",
    component: () => import("./views/ProductTypeListPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
  {
    path: "/product-types/:productTypeId",
    name: "ProductTypeDetailsPage",
    component: () => import("./views/ProductTypeDetailsPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
];
