import { h } from "vue";

import type { ValidationError } from "../models";

/**
 * Returns a render function that can be used for an anonymous component definition
 * to be used as toast content.
 * @param error The validation error instance
 */
export const createValidationErrorToastRenderFn = (
  error: ValidationError,
  showFailures = false,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const childNodes: any[] = ["Action did not succeed!"];
  if (showFailures) {
    childNodes.push(...error.failures.map((f) => [h("br"), f.errorMessage]));
  }

  return () => h("span", childNodes);
};
