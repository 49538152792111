<script setup lang="ts">
import { useErrorComponent } from "../composables";
import type { ErrorComponentProps } from "../types";

const props = defineProps<ErrorComponentProps>();
const { currentError } = useErrorComponent(props);
</script>

<template>
  <span v-if="currentError">
    {{ currentError.message }}
  </span>
</template>
