import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import {
  computed,
  type MaybeRef,
  onBeforeUnmount,
  onMounted,
  unref,
  watch,
} from "vue";

import { usePlatformStore } from "@/platforms/store";

import { ACCESS_TOKEN_STORAGE_KEY } from "../utils/constants";

const wsApiUrl = import.meta.env.VITE_WEBSOCKET_API;

export const PUBLIC_HUB = "/hubs/updates";
export const ADMIN_HUB = "/hubs/updates";

export function useWebSocketsPublicHub(): HubConnection {
  const platformStore = usePlatformStore();

  return new HubConnectionBuilder()
    .withUrl(
      `${wsApiUrl}${PUBLIC_HUB}?platform_id=${platformStore.platformKey}`,
      {
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () =>
          localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) ?? "",
      },
    )
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect()
    .build();
}

export function useWebSocketsAdminHub(): HubConnection {
  const platformStore = usePlatformStore();

  return new HubConnectionBuilder()
    .withUrl(
      `${wsApiUrl}${ADMIN_HUB}?platform_id=${platformStore.platformKey}`,
      {
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () =>
          localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) ?? "",
      },
    )
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect()
    .build();
}

export function connectWebSocketsAdminHub(
  channel: string,
  handler: (...args: unknown[]) => unknown,
  onConnectError: (error: unknown) => void,
  isActive?: MaybeRef<boolean>,
) {
  try {
    const connection = useWebSocketsAdminHub();

    connection.on(channel, handler);

    const startConnection = async () => {
      try {
        await connection.start();
      } catch (error) {
        onConnectError(error);
      }
    };

    const shouldConnect = computed(() => {
      return isActive === undefined ? true : unref(isActive);
    });

    onMounted(() => {
      if (shouldConnect.value) {
        startConnection();
      }
    });

    watch(shouldConnect, (newValue) => {
      if (newValue) {
        startConnection();
      } else {
        connection.stop();
      }
    });

    onBeforeUnmount(() => {
      connection.stop();
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}
