export const legalOrganisationRoutes = [
  {
    path: "/legal-organisations",
    name: "LegalOrganisationListPage",
    component: () => import("./views/LegalOrganisationListPage.vue"),
    meta: {
      auth: ["FinanceManager.Access"],
    },
  },
  {
    path: "/legal-organisations/:legalOrganisationId",
    name: "LegalOrganisationDetailsPage",
    component: () => import("./views/LegalOrganisationDetailsPage.vue"),
    meta: {
      auth: {
        roles: ["FinanceManager.Access"],
      },
    },
  },
];
