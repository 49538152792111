export const customerRoutes = [
  {
    path: "/customers/:customerId/address/create",
    name: "CreateCustomerAddressPage",
    component: () => import("../customers/views/CreateCustomerAddressPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
  { path: "/users", redirect: { path: "/customers" } },
  {
    path: "/customers",
    name: "CustomersListPage",
    component: () => import("../customers/views/CustomersListPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
  {
    // /users/:userId -> /customers/:customerId?asUser=true
    path: "/users/:userId",
    redirect: (to: { params: { userId: string } }) => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return {
        path: `/customers/${to.params.userId}`,
        query: { isUserId: true },
      };
    },
  },
  {
    path: "/customers/:customerId",
    name: "CustomerDetailsPage",
    component: () => import("./views/CustomersDetailsBase.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
    redirect: { name: "CustomerDetails.Overview" },
    children: [
      {
        path: "",
        name: "CustomerDetails.Overview",
        component: () => import("./views/CustomerDetailsOverviewPage.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "addresses",
        name: "CustomerDetails.Addresses",
        component: () => import("./views/CustomerDetailsAddressesPage.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "notes",
        name: "CustomerDetails.Notes",
        component: () => import("./views/CustomerDetailsNotesPage.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "orders",
        name: "CustomerDetails.Orders",
        component: () => import("./views/CustomerDetailsOrdersPage.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "notifications",
        name: "CustomerDetails.Notifications",
        component: () => import("./views/CustomerDetailsNotificationsPage.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "roles",
        name: "CustomerDetails.Roles",
        component: () => import("./views/CustomerDetailsRolesPage.vue"),
        meta: {
          auth: ["Admin.Access", "Users.SetRoles"],
        },
      },
      {
        path: "bids",
        name: "CustomerDetails.Bids",
        component: () => import("./views/CustomerDetailsBids.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
    ],
  },
  {
    path: "/customers/:customerId/address/:addressId",
    name: "UpdateCustomerAddressPage",
    component: () => import("../customers/views/UpdateCustomerAddressPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
];
