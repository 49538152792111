<script lang="ts" setup>
import type { DefaultColors } from "tailwindcss/types/generated/colors";
import { computed } from "vue";

const props = defineProps<{ color?: keyof DefaultColors; border?: boolean }>();

const color = computed(() => props.color ?? "gray");
const border = computed(() => props.border ?? false);
</script>

<template>
  <span
    :class="`inline-flex items-center justify-center rounded-full bg-${color}-200 ${
      border ? `border border-solid border-${color}-700` : ''
    } px-3 py-1 text-sm font-medium text-${color}-700`"
  >
    <slot />
  </span>
</template>
