export const athleteRoutes = [
  {
    path: "/athletes",
    name: "AthletesListPage",
    component: () => import("./views/AthletesListPage.vue"),

    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
  {
    path: "/athletes/:athleteId",
    name: "AthleteDetails",
    component: () => import("./views/AthleteDetailsBase.vue"),
    meta: {
      auth: ["Admin.Access"],
    },
    redirect: { name: "AthleteDetails.Details" },
    children: [
      {
        path: "",
        name: "AthleteDetails.Details",
        component: () => import("./views/AthleteDetails.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "rosters",
        name: "AthleteDetails.Rosters",
        component: () => import("./views/AthleteDetailsRosters.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "lineups",
        name: "AthleteDetails.Lineups",
        component: () => import("./views/AthleteDetailsLineups.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "product-listings",
        name: "AthleteDetails.ProductListings",
        component: () => import("./views/AthleteDetailsProductListings.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "external-provider-records",
        name: "AthleteDetails.ExternalProviderRecords",
        component: () =>
          import("./views/AthleteDetailsExternalProviderRecords.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
    ],
  },
];
