import { PLATFORM_STORAGE_KEY } from "@/common/utils/constants";
import { usePlatformStore } from "@/platforms/store";

const MWS_PLATFORM_ID = import.meta.env.VITE_MWS_PLATFORM_ID;

/**
 * Setting up the initial state for the store.
 */
export default () => {
  const platformStore = usePlatformStore();

  // Storing in local storage is needed because the platform selector does a full page reload.
  // See PlatformPicker.vue.
  const platformKey =
    localStorage.getItem(PLATFORM_STORAGE_KEY) || MWS_PLATFORM_ID;
  platformKey && platformStore.setPlatformKey(platformKey);
};
