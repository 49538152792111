/* eslint-disable @typescript-eslint/naming-convention */
/**
 * This file contains our
 */

export const basicTagsList = {
  "family:tags__label": {
    block: true,
    "mb-1": true,
    "font-bold": true,
    "text-sm": true,
    "formkit-invalid:text-error": true,
  },
  "family:tags__inner": {
    "formkit-invalid:border-error": true,
    "mb-1": true,
  },
  "family:tags__input": {
    "w-full": true,
    "h-10": true,
    "px-3": true,
    border: true,
    "border-gray-400": true,
    "text-base": true,
    "text-gray-900": true,
    "placeholder-gray-400": true,
    "focus-within:border-indigo-500": true,
    "rounded-lg": true,
  },
  "family:tags__chip": {
    border: true,
    "border-gray-400": true,
    rounded: true,
    "bg-indigo-100": true,
    "text-indigo-700": true,
  },
};

export const form__formCustomStyles = {
  flex: true,
  "flex-col": true,
  "gap-4": true,
};

export const submit__outerCustomStyles = {
  "max-w-[24em]": false,
};

export const submit__wrapperCustomStyles = {
  flex: true,
  "justify-end": true,
};

export const taglist__tagLabelCustomStyles = {
  "pl-0.5": true,
};

export const error__inputCustomStyles = {
  "group-data-[errors]:border-red-400": true,
  "group-data-[errors]:ring-1": true,
  "group-data-[errors]:ring-red-400": true,
};

export const select__optionCustomStyles = {
  "text-black": true,
};
