export const leagueRoutes = [
  {
    path: "/leagues",
    name: "LeaguesListPage",
    component: () => import("../leagues/views/LeaguesListPage.vue"),

    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
  {
    path: "/leagues/:leagueId",
    name: "LeagueDetailsPage",
    component: () => import("../leagues/views/LeagueDetailsBase.vue"),
    meta: {
      auth: ["Admin.Access"],
    },
    redirect: { name: "LeagueDetails.Details" },
    children: [
      {
        path: "",
        name: "LeagueDetails.Details",
        component: () => import("../leagues/views/LeagueDetails.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "seasons",
        name: "LeagueDetails.Seasons",
        component: () => import("../leagues/views/LeagueSeasons.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
    ],
  },
];
