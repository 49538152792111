/**
 * This utility function returns the URL of a file.
 * Especially useful for showing preview of images uploaded with the `<input>` tag.
 * @param file File object
 */
export function getFileUrl(file?: File) {
  if (file) return URL.createObjectURL(file);

  return "";
}

/**
 * This utility function returns filename + extension by taking the last split segment from the file uri
 * @param file File object
 */
export function getSafeFileName(fileName: string, fileUri: string) {
  const uriExtension = `.${fileUri.split(".").pop()}`.toLowerCase();
  if (fileName.toLowerCase().endsWith(uriExtension)) {
    return fileName;
  }

  return `${fileName}${uriExtension}`;
}

export type PhotoProxy = {
  /** ImageUrl is a URL derived from a uploaded File that allows passing it to the html <img> element as a src="". */
  imageUrl: string;
  imageName: string;
};

export const getPhotoProxyFromFile = async (
  file: File,
): Promise<PhotoProxy> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () =>
      resolve({ imageName: file.name, imageUrl: reader.result as string });
    reader.onerror = () => reject(new Error("Error reading file"));
    reader.readAsDataURL(file);
  });
};
