<script setup lang="ts">
import type { FormKitFrameworkContext } from "@formkit/core";
import { slugify } from "@formkit/utils";

const props = defineProps<{
  context: FormKitFrameworkContext;
}>();

const handleInput = (e: Event) => {
  const target = e.target as HTMLInputElement;
  let value = target.value as string;
  if (!value) return "";

  let addDash = false;
  value = value.replace(/\s+/g, "-");

  if (value.charAt(value.length - 1) === "-" && value.length > 1) {
    addDash = true;
  }

  let slug = slugify(value);

  if (addDash) {
    slug = `${slug}-`;
  }

  props.context.node.input(slug);
};
</script>

<template>
  <input
    :id="context.id"
    type="text"
    :value="context._value"
    :class="context.classes.input"
    :disabled="!!context.disabled"
    v-bind="context.attrs"
    @input="handleInput"
  />
</template>
