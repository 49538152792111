<script setup lang="ts">
import type { FormKitFrameworkContext } from "@formkit/core";

import TagsInput from "@/common/components/TagsInput.vue";

const props = defineProps<{
  context: FormKitFrameworkContext;
}>();
</script>

<template>
  <TagsInput v-bind="props" />
</template>
