import type { AdminCustomerDto } from "mws-finance-ts-sdk";

export const isAdminCustomerDto = (
  customer: unknown,
): customer is AdminCustomerDto => {
  return (
    (customer as AdminCustomerDto).id !== undefined &&
    (customer as AdminCustomerDto).userId !== undefined &&
    (customer as AdminCustomerDto).emailAddress !== undefined &&
    (customer as AdminCustomerDto).addressReminderSent !== undefined &&
    (customer as AdminCustomerDto).creditUpdates !== undefined
  );
};
