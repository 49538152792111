<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/20/solid";
import { computed, type FunctionalComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";

const props = withDefaults(
  defineProps<{
    title: string;
    options: { name: string; link?: string; value?: string }[];
    // `dropdownType` determines if the items in the dropdown will go inside its container or not
    icon?: FunctionalComponent | null;
    dropdownType?: "static" | "floating";
    // depending on the `optionType`, we show a link or a button. if it is a button, we emit an event when it is clicked
    optionType?: "link" | "button";
    /** this prop is used to distinguish styling from the usage of this component in the header vs. the sidebar */
    theme?: "light" | "dark";
    width?: "auto" | "full";
    closeOnClick?: boolean;
  }>(),
  {
    icon: null,
    dropdownType: "static",
    optionType: "link",
    theme: "dark",
    width: "full",
  },
);

defineEmits<{
  (e: "item-click", value: string | undefined, name: string): void;
}>();

// if `dropdownType` is static, we need to keep track of whether the dropdown is open or not manually
// otherwise we use the `open` value that the `Menu` component provides
const isDropdownOpen = ref(false);

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};

const route = useRoute();
watch(
  route,
  () => {
    if (props.options.some((opt) => opt.link === route.path)) {
      isDropdownOpen.value = true;
    }
  },
  { immediate: true },
);

const themeStyles = computed(() => {
  if (props.theme === "dark") {
    return "bg-slate-700 text-white";
  }

  return "bg-slate-100 text-slate-700";
});

const widthClass = computed(() => {
  return `w-${props.width}`;
});
</script>

<template>
  <Menu
    v-slot="{ open, close }"
    as="div"
    class="relative inline-block text-left"
    :class="[themeStyles, widthClass]"
  >
    <div>
      <MenuButton
        class="flex justify-start items-center w-full py-2 px-4 text-sm"
        :class="[
          theme === 'dark' ? 'hover:bg-slate-500' : 'hover:bg-slate-200',
        ]"
        @click="toggleDropdown()"
      >
        <div class="flex flex-1 items-center gap-x-4">
          <component
            :is="icon"
            v-if="icon"
            class="size-6 text-white"
          />
          {{ title }}
        </div>

        <component
          :is="
            (dropdownType === 'static' ? isDropdownOpen : open)
              ? ChevronUpIcon
              : ChevronDownIcon
          "
          class="-mr-1 ml-1 size-5"
          aria-hidden="true"
        />
      </MenuButton>
    </div>

    <Transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        v-show="dropdownType === 'static' ? isDropdownOpen : open"
        :static="dropdownType === 'static'"
        class="origin-top-right bg-slate-600"
        :class="[
          widthClass,
          dropdownType === 'floating'
            ? `absolute right-0  z-10`
            : 'relative w-full',
        ]"
      >
        <div
          class="max-h-[80vh] overflow-auto"
          @click="
            () => {
              closeOnClick && close();
              $emit('item-click', undefined, 'close');
            }
          "
        >
          <MenuItem
            v-for="navigation in options"
            :key="navigation.name"
            :data-cy="`vue-dropdown-menu-item-${navigation.name
              .toLowerCase()
              .replace(' ', '-')}`"
          >
            <RouterLink
              v-if="optionType === 'link' && navigation.link"
              :to="navigation.link"
              class="flex items-center p-2 pl-10 text-sm justify-between group text-white hover:bg-slate-500"
              active-class="bg-slate-400 border-r-4"
            >
              {{ navigation.name }}
            </RouterLink>
            <button
              v-else-if="optionType === 'button'"
              class="flex items-center p-2 text-sm justify-between group w-full text-white hover:bg-slate-500"
              @click="$emit('item-click', navigation.value, navigation.name)"
            >
              {{ navigation.name }}
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </Transition>
  </Menu>
</template>
