export const fabricksTrackableProductsRoutes = [
  {
    // trackable products
    path: "/fabricks/trackable-products",
    name: "TrackableProductsListPage",
    component: () => import("./views/TrackableProductsListPage.vue"),
    meta: {
      auth: {
        roles: ["FabricksAdmin.Access"],
      },
    },
  },
  {
    // trackable product details
    path: "/fabricks/trackable-products/:trackableProductId",
    name: "TrackableProductDetailsPage",
    component: () => import("./views/TrackableProductDetailsPage.vue"),
    meta: {
      auth: {
        roles: ["FabricksAdmin.Access"],
      },
    },
    redirect: { name: "TrackableProductDetails.Details" },
    children: [
      {
        path: "",
        name: "TrackableProductDetails.Details",
        component: () => import("./views/TrackableProductDetails.vue"),
        meta: {
          auth: {
            roles: ["FabricksAdmin.Access"],
          },
        },
      },
      {
        path: "update",
        name: "TrackableProductDetails.DemoSettings",
        component: () => import("./views/TrackableProductDemoSettings.vue"),
        meta: {
          auth: {
            roles: ["FabricksAdmin.Access"],
          },
        },
      },
      {
        path: "chips",
        name: "TrackableProductDetails.Chips",
        component: () => import("./views/TrackableProductChips.vue"),
        meta: {
          auth: {
            roles: ["FabricksAdmin.Access"],
          },
        },
      },
    ],
  },
];
