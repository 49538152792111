<script setup lang="ts">
import { onMounted } from "vue";

import ReloadPrompt from "@/misc/components/ReloadPrompt.vue";

import ErrorBoundary from "./plugins/errors/components/ErrorBoundary.vue";
import ErrorToast from "./plugins/errors/components/ErrorToast.vue";
import ErrorValidation from "./plugins/errors/components/ErrorValidation.vue";

onMounted(() => {
  // 2024-03-08: Clean up dark mode if set. Can be removed after a while.
  localStorage.removeItem("vueuse-color-scheme");
});
</script>

<template>
  <div>
    <ErrorBoundary>
      <ErrorBoundary>
        <ReloadPrompt />
        <component :is="$route.meta.layout">
          <RouterView />
        </component>

        <!-- If any validation errors slip through, we still want to catch those and handle them
           without risking that they bubble up to the global handler and make their way into
           Sentry -->
        <ErrorValidation :show-failures="true" />
      </ErrorBoundary>

      <!-- Since this is the fallback error handler, we do not mark the error as handled, and we
           instruct that the error should be propagated upwards so it can be caught by the global
           error and/or Sentry. -->
      <ErrorToast
        :propagate="true"
        :handled="false"
      />
    </ErrorBoundary>
  </div>
</template>

<style>
html {
  scroll-behavior: smooth;
}
</style>
