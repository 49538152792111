import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/vue-query";
import {
  AdminLabelsApi,
  type AdminLabelsLabelIdPatchRequest,
  type AdminLabelsPostRequest,
  type LabelDto,
  LabelsApi,
} from "mws-catalog-ts-sdk";
import { useToast } from "vue-toastification";

import type { Guid, UseQueryOptionsOmitQueryKey } from "@/common/types";

const catalogApiUrl = import.meta.env.VITE_CATALOG_API;
const adminLabelApi = new AdminLabelsApi(undefined, catalogApiUrl);
const labelApi = new LabelsApi(undefined, catalogApiUrl);
export type UpdateOrCreateLabelRequest = {
  text: string;
  description: string;
};

export const useGetAllLabelsQuery = (
  options?: UseQueryOptionsOmitQueryKey<LabelDto[]>,
) => {
  const queryKey = ["labels"];

  const queryFn = async () => {
    const response = await labelApi.labelsGet();

    return response.data;
  };

  return useQuery({
    queryKey,
    queryFn,
    placeholderData: keepPreviousData,
    ...options,
  });
};

export const useGetLabelByIdQuery = (
  id: Guid,
  options?: UseQueryOptionsOmitQueryKey<LabelDto>,
) => {
  const queryKey = ["labels", id];
  const queryFn = async () => {
    const response = await adminLabelApi.adminLabelsLabelIdGet({
      labelId: id,
    });

    return response.data;
  };

  return useQuery({
    queryKey,
    queryFn,
    placeholderData: keepPreviousData,
    ...options,
  });
};

export const useCreateLabelMutation = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const mutationFn = async (request: AdminLabelsPostRequest) => {
    const response = await adminLabelApi.adminLabelsPost({
      adminLabelsPostRequest: request,
    });

    return response.data;
  };

  return useMutation({
    mutationFn: mutationFn,

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["labels"] });
      toast.success("Label created");
    },
    onError: () => {
      toast.error("Error creating label");
    },
  });
};

export const useUpdateLabelMutation = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const mutationFn = async ({
    request,
    labelId,
  }: {
    request: AdminLabelsLabelIdPatchRequest;
    labelId: Guid;
  }) => {
    const response = await adminLabelApi.adminLabelsLabelIdPatch({
      labelId,
      adminLabelsLabelIdPatchRequest: request,
    });

    return response.data;
  };

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["labels"] });
    },
    onError: () => {
      toast.error("Error updating label");
    },
  });
};
