export const framingConfigurationRoutes = [
  {
    path: "/framing/offers/:offerId/configurations/:configurationId",
    name: "ConfigurationDetailPage",
    component: () => import("./views/ConfigurationDetailPage.vue"),
    meta: {
      auth: ["FramingAdmin.Access"],
    },
    redirect: { name: "ConfigurationDetails.Details" },
    children: [
      {
        path: "",
        name: "ConfigurationDetails.Details",
        component: () => import("./views/ConfigurationDetail.vue"),
        meta: {
          auth: ["FramingAdmin.Access"],
        },
      },
      {
        path: "assets",
        name: "ConfigurationDetails.Assets",
        component: () => import("./views/ConfigurationAssets.vue"),
        meta: {
          auth: ["FramingAdmin.Access"],
        },
      },
      {
        path: "configurator",
        name: "ConfigurationDetails.Configurator",
        component: () =>
          import("../offers/components/OfferDetailsConfiguration.vue"),
        meta: {
          auth: ["FramingAdmin.Access"],
        },
      },
    ],
  },
  {
    path: "/framing/configuration-templates",
    name: "ConfigurationTemplatesListPage",
    meta: {
      auth: ["FramingAdmin.Access"],
    },
    children: [
      {
        path: "",
        name: "ConfigurationTemplatesListPage",
        component: () => import("./views/ConfigurationTemplatesListPage.vue"),
        meta: {
          auth: ["FramingAdmin.Access"],
        },
      },
      {
        path: ":templateId",
        name: "ConfigurationTemplates.ConfigurationTemplateDetails",
        component: () => import("./views/ConfigurationTemplateDetailPage.vue"),
        meta: {
          auth: ["FramingAdmin.Access"],
        },
        children: [
          {
            path: "",
            name: "ConfigurationTemplates.ConfigurationTemplateDetails.Details",
            component: () => import("./views/ConfigurationTemplateDetail.vue"),
            meta: {
              auth: ["FramingAdmin.Access"],
            },
          },
          {
            path: "assets",
            name: "ConfigurationTemplates.ConfigurationTemplateDetails.Assets",
            component: () => import("./views/ConfigurationAssets.vue"),
            meta: {
              auth: ["FramingAdmin.Access"],
            },
          },
          {
            path: "configurator",
            name: "ConfigurationTemplates.ConfigurationTemplateDetails.Configurator",
            component: () =>
              import("./views/ConfigurationTemplateConfigurator.vue"),
            meta: {
              auth: ["FramingAdmin.Access"],
            },
          },
        ],
      },
    ],
  },
];
