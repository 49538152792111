import axios, { isAxiosError } from "axios";
import {
  v2RequestSnakeCaseTransformer,
  v2ResponseCamelCaseTransformer,
} from "mws-utils/lib/caseTransformers/axiosCaseTransformers";
import { dateTransformer } from "mws-utils/lib/dateTransformer";

import {
  ACCESS_TOKEN_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
} from "@/common/utils/constants";
import cognito from "@/misc/drivers/cognito";
import type { AdminApp } from "@/misc/types";
import { isRefreshRequest } from "@/plugins/utils/isRefreshRequest";

export default (app: AdminApp) => {
  app.axios = axios;
  app.$http = axios;

  // A simple AxiosTransformer utility to transform date-time strings from
  // JSON data into JS Date objects.
  axios.defaults.transformResponse = [dateTransformer];

  app.config.globalProperties.axios = axios;
  app.config.globalProperties.$http = axios;

  // set the initial auth token from localStorage
  // the new ones will be set by vue-auth within an interceptor
  const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
  if (token) {
    app.axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  app.axios.interceptors.request.use(v2RequestSnakeCaseTransformer);

  // adding an interceptor to handle failing refresh call
  app.axios.interceptors.response.use(
    v2ResponseCamelCaseTransformer,
    (error) => {
      const err = error;
      if ("response" in err) {
        err.response = v2ResponseCamelCaseTransformer(err.response);
      }

      if (
        isAxiosError(error) &&
        error.request &&
        isRefreshRequest(error.request) &&
        err.response.status === 401
      ) {
        let refreshToken;
        try {
          refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }

        app.auth?.logout({
          makeRequest: true,
          data: { token: refreshToken, client_id: cognito.params.client_id },
          redirect: "/login",
        });

        try {
          localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }

        return err;
      }

      throw err;
    },
  );
};
