import AnonymousLayout from "@/layouts/AnonymousLayout.vue";

export const inboundReportRoutes = [
  {
    path: "/events/:eventId/outbound-report/:reportId/products/add",
    name: "InboundProductAddPage",
    meta: { layout: AnonymousLayout },
    component: () => import("./views/ProductCreatePage.vue"),
  },
  {
    path: "/events/:eventId/outbound-report/:reportId/products/update/:productId",
    name: "InboundProductEditPage",
    meta: { layout: AnonymousLayout },
    component: () => import("./views/ProductEditPage.vue"),
  },
  {
    path: "/events/:eventId/outbound-report/:reportId",
    name: "ProductCheckList",
    meta: { layout: AnonymousLayout },
    component: () => import("./views/ProductCheckList.vue"),
  },
  {
    path: "/events/:eventId/outbound-report/:reportId/submitted",
    name: "InboundReportSubmitSuccessPage",
    meta: { layout: AnonymousLayout },
    component: () => import("./views/InboundReportSubmittedPage.vue"),
  },
];
