import type { UseQueryOptions } from "@tanstack/vue-query";
import { Type } from "class-transformer";
import type { FunctionalComponent } from "vue";
import type { RouteLocationRaw } from "vue-router";

import type { Permission } from "@/permissions/types";
import type { ExternalSyncStatus } from "@/sports/types";

export type DateTime = string; // e.g. "2022-02-22T11:37:18.352Z"
export type Guid = string; // e.g. "3fa85f64-5717-4562-b3fc-2c963f66afa6"
export type Html = string;
export type ShortGuid = {
  guid: Readonly<Guid>;
  value: string | null;
};

export class Entity {
  id!: Guid;
  @Type(() => Date)
  dateCreated!: Date;
  @Type(() => Date)
  dateModified!: Date;
  createdBy!: Guid;
  modifiedBy!: Guid;
}

export type Gender = "men" | "women";

/* Method enum was based on the `Method` type from axios */
export enum Method {
  GET = "GET",
  DELETE = "DELETE",
  HEAD = "HEAD",
  OPTIONS = "OPTIONS",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  PURGE = "PURGE",
  LINK = "LINK",
  UNLINK = "UNLINK",
}

export type NavigationLink = {
  name: string;
  link: string;
  permissionsNeeded: Permission[];
  icon: FunctionalComponent; // e.g. icon components from @heroicons/vue
  isBeta?: boolean;
  isExternalLink?: boolean;
};

export type UnknownObject = Record<string, unknown>;

export type PaginatedResult<T> = {
  totalCount: number;
  resultCount: number;
  offset: number;
  pageSize: number;
  results: T[];
};

export type DropdownOption = {
  name: string;
  link?: string;
  value?: string;
};

export type TabElement = {
  title: string;
  to?: RouteLocationRaw;
  count?: number | null;
  error?: boolean | null;
  detail?: string | null;
  visible?: boolean | null;
  isPending?: boolean;
};

// this type is used for tabs that don't have a route
export type ResponsiveTab = {
  current: boolean;
} & TabElement;

export enum Default {
  Guid = "00000000-0000-0000-0000-000000000000",
}

export type WithOptional<T, K extends keyof T> = Omit<T, K> &
  Partial<Pick<T, K>>;

export type NullableToOptional<T> = {
  [K in keyof T]: null extends T[K] ? NonNullable<T[K]> | undefined : T[K];
};

export type OptionalToNullable<T> = {
  [K in keyof T]: undefined extends T[K]
    ? Exclude<T[K], undefined> | null
    : T[K];
};

export type FormSectionDetails = Record<
  string,
  | {
      type: "string" | "clipboard" | "user" | "countryCode";
      value: string;
      showFlag?: boolean;
    }
  | {
      type: "date";
      value: Date | null;
    }
  | {
      type: "boolean";
      value: boolean;
    }
  | {
      type: "link";
      label: string;
      path: string;
      isExternalLink?: boolean;
      isCopyable?: boolean;
    }
  | {
      type: "valueInCurrency";
      value: number;
      isValueInCents: boolean;
      tooltip?: string;
    }
  | undefined
>;

/**
 * ISO 3166-1 alpha-2
 *
 * ISO 3166-1 alpha-2 codes are two-letter country codes defined in
 * ISO 3166-1, part of the ISO 3166 standard[1] published by the
 * International Organization for Standardization (ISO), to represent
 * countries, dependent territories, and special areas of geographical
 * interest.
 */
export type Alpha2IsoCountryCode = string;

export class Amount {
  [isoCurrency: string]: number;
}

export type Label = {
  id: string;
  text: string | null;
  description: string | null;
};

export enum MediaContentType {
  Video = "video",
  Image = "image",
}

export enum CatalogShippingType {
  FreeShipping = "freeShipping",
  PaidShipping = "paidShipping",
  NoShipping = "noShipping",
}

export enum Visibility {
  Published = "published",
  Unpublished = "unpublished",
}

export type MediaContentMetaData = {
  origin: string;
  applyPadding: boolean;
};

export type ProviderRecord = {
  providerSlug: string;
  externalSyncStatus: ExternalSyncStatus | null;
  externalId: string | null;
  externalLogoPath: string | null;
};

export type MediaContent = {
  type: MediaContentType | null;
  url: string | null;
  visibility: boolean;
  metaData: MediaContentMetaData | null;
};

export type TextSize =
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "2xl"
  | "3xl"
  | "4xl"
  | "5xl"
  | "6xl"
  | "7xl"
  | "8xl"
  | "9xl";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ExtendsFunction<T> = T extends (...args: any[]) => any ? true : false;

// based on https://dev.to/pffigueiredo/typescript-utility-keyof-nested-object-2pa3
// but doesn't take into account keys that have function values
export type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ExtendsFunction<
    ObjectType[Key]
  > extends true
    ? never
    : ObjectType[Key] extends object
      ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
      : `${Key}`;
}[keyof ObjectType & (string | number)];

export type NestedValueOf<
  T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  K extends keyof any,
> = K extends `${infer FirstKey}.${infer RemainingKeys}`
  ? FirstKey extends keyof T
    ? NestedValueOf<T[FirstKey], RemainingKeys>
    : unknown
  : K extends keyof T
    ? T[K]
    : unknown;

export type UseQueryOptionsOmitQueryKey<TQueryFnData = unknown> = Omit<
  UseQueryOptions<TQueryFnData, unknown, TQueryFnData>,
  "queryKey" | "queryFn"
>;
