export const notificationRoutes = [
  {
    path: "/notifications",
    name: "NotificationsHubPage",
    component: () => import("../notifications/views/NotificationsHub.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
];
