import type { ComponentPublicInstance } from "vue";

import type { ValidationFailure } from "../types";
import { HttpError } from "./httpError";

export class ValidationError extends HttpError<ValidationFailure[]> {
  public constructor(
    sourceComponent: ComponentPublicInstance | null,
    sourceError: Error,
    statusCode: number,
    failures: ValidationFailure[],
  ) {
    super(sourceComponent, sourceError, statusCode, failures);
  }

  public get failures(): ValidationFailure[] {
    return this.responseData;
  }
}
