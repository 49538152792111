import { type MaybeRef, ref, type Ref, watch } from "vue";
import { useToast } from "vue-toastification";

import {
  type BarcodeScan,
  type SerialScanEvent,
  useBarcodeScanning,
} from "@/common/composables/useBarcodeScanning";
import { connectWebSocketsAdminHub } from "@/common/composables/useWebSockets";
import { getErrorMessage } from "@/common/utils/getErrorMessage";
import {
  asChipRequestByUidSignalREvent,
  type ChipScanEvent,
} from "@/common/utils/signalREventMapper";
import { getSerialScanData } from "@/products/products/api/queries";

export const useOrderPageScanning = () => {
  const toast = useToast();
  const chipScannedEvent = useRealTimeScanning();

  const scannedOrderId = ref<string | undefined>(undefined);

  watch(
    chipScannedEvent,
    () => {
      if (chipScannedEvent.value?.orderId === null) {
        toast.warning("No order", { timeout: 5000 });

        return;
      }

      scannedOrderId.value = chipScannedEvent.value?.orderId;
    },
    { immediate: false },
  );

  return scannedOrderId;
};

type Params = {
  /**
   * Whether the real-time scanning should be active or not.
   * If not provided, it will be active by default.
   */
  isActive?: MaybeRef<boolean>;
};

export const useRealTimeScanning = (params?: Params) => {
  const toast = useToast();
  const chipScannedEvent: Ref<ChipScanEvent | SerialScanEvent | null> =
    ref(null);

  try {
    connectWebSocketsAdminHub(
      "all",
      (ev) => {
        chipScannedEvent.value = asChipRequestByUidSignalREvent(ev);
      },
      (error) => {
        // eslint-disable-next-line no-console
        console.error("Error connecting to real-time updates", error);
        toast.error(
          `Error connecting to real-time updates: ${getErrorMessage(error)[0]}`,
        );
      },
      params?.isActive,
    );

    const scannedBarcode = useBarcodeScanning();
    watch(scannedBarcode, async (newBarcodeScan) => {
      if (!newBarcodeScan?.barcodeContent) return;

      chipScannedEvent.value = await getScanDataBySerial(newBarcodeScan);
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  return chipScannedEvent;
};

const getScanDataBySerial = async (
  barcodeScan: BarcodeScan,
): Promise<SerialScanEvent> => {
  const response = await getSerialScanData(barcodeScan.barcodeContent!);

  return {
    chipId: response.chipId ?? "",
    orderId: response.orderId ?? "",
    externalReferenceId: response.productListingId ?? "",
    barcodeType: barcodeScan.barcodeType!,
  };
};
