export const orderRoutes = [
  {
    path: "/orders",
    name: "OrdersListPage",
    component: () => import("./views/OrdersListPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
  {
    path: "/orders/create/product-listings/:productListingId",
    name: "OrderCreatePage",
    component: () => import("./views/CreateOrderPage.vue"),
    meta: {
      auth: ["Admin.Access"],
    },
  },
  {
    path: "/orders/:orderId",
    name: "OrderDetailsPage",
    component: () => import("./views/OrderDetailsBase.vue"),
    meta: {
      auth: ["Admin.Access"],
    },
    children: [
      {
        path: "",
        name: "VueOrderDetails.Details",
        component: () => import("./views/OrderDetails.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "customer",
        name: "VueOrderDetails.Customer",
        component: () => import("./views/OrderCustomer.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "shipping",
        name: "VueOrderDetails.Shipping",
        component: () => import("./views/OrderShipping.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "transactions",
        name: "VueOrderDetails.Transactions",
        component: () => import("./views/OrderTransactions.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "documents",
        name: "VueOrderDetails.Documents",
        component: () => import("./views/OrderDocuments.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "items",
        name: "VueOrderDetails.OrderItems",
        component: () => import("./views/OrderItems.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
      {
        path: "chip-scan",
        name: "VueOrderDetails.ChipScan",
        component: () => import("./views/OrderChipScan.vue"),
        meta: {
          auth: ["Admin.Access"],
        },
      },
    ],
  },
];
