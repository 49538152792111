import { Type } from "class-transformer";
import {
  GoLiveTiming,
  OnFixtureResult,
  type TeamCategoryDto,
} from "mws-catalog-ts-sdk";

import type { Beneficiary } from "@/beneficiaries/types";
import type { Category } from "@/categories/types";
import type { Guid, ShortGuid } from "@/common/types";

export enum EventPublishedStatus {
  Published = "PUBLISHED",
  Unpublished = "UNPUBLISHED",
  Draft = "DRAFT",
  Cancelled = "CANCELLED",
  Confirmed = "CONFIRMED",
}

export enum EventType {
  SportFixture = "SPORTFIXTURE",
  Team = "TEAM",
  TeamV2 = "TEAMV2",
}

/**
 * @deprecated
 */
export class MarketplaceEvent {
  readonly id!: Guid;
  readonly shortId!: ShortGuid | null;
  readonly externalId!: Guid;
  readonly name!: string | null;
  readonly forceEventName!: boolean;
  readonly externalType!: string | null;
  readonly status!: EventPublishedStatus | null;
  readonly eventType!: EventType | null;
  readonly slug!: string;
  readonly heroBannerOverlayColor!: string | null;
  readonly isFinished!: boolean;
  readonly featuredTeamCategories!: TeamCategoryDto[] | null;
  readonly participatingTeamCategories!: TeamCategoryDto[] | null;
  readonly productPublishSettings!: {
    publishingDisabled: boolean;
    autoPublishNonParticipatingLineup: boolean;
    autoPublishParticipationChanges: boolean;
    autoPublishInitialLineup: boolean;
    featureGoalBallProducts: boolean;
    featureGoalBallsOnPlatformIds: Guid[];
  };
  readonly defaultFrameMockupSetId!: Guid;
  readonly syncProductDates!: boolean;
  readonly mediaVisibility!: boolean;
  readonly headerImageUrl!: string | null;
  readonly highlightedProductIds!: string[] | null; // will be `null` if fetched by a "get multiple events" request. only will be valid if fetched by a "get single event" request
  readonly categories!: Category[] | null; // null means we didn't include in the response empty means no category
  @Type(() => Date)
  readonly startDate!: Date;
  @Type(() => Date)
  readonly endDate!: Date;
  @Type(() => Date)
  readonly dateCreated!: Date;
  @Type(() => Date)
  readonly dateModified!: Date;
  readonly createdBy!: Guid;
  readonly modifiedBy!: Guid;
  readonly manualPredictedYield!: number | null;
  readonly predictedYield!: number;
  readonly predictedYieldProductAmount!: number;
  readonly excludeFromHomePage!: boolean;
  readonly excludeFromNowLive!: boolean;
  readonly endingNoticeSent!: boolean;
  readonly highlightedOnHomepage!: boolean;
  readonly asSpecialTileOnHomePage!: boolean;
  readonly highlightedInCategory!: boolean;
  readonly priorityScore!: number;
  readonly beneficiary!: Beneficiary | null;
  readonly goLiveTiming!: GoLiveTiming;
  readonly onFixtureResult!: OnFixtureResult;
  readonly teamTier!: number | null;
  readonly financeSettings!: MarketplaceEventFinanceSettings;
  public getAdminPageLink(): string {
    return `/events/${this.id}`;
  }
}

/**
 * @deprecated
 */
export class MarketplaceEventFinanceSettings {
  readonly contractId!: string;
  readonly isAdditional!: boolean;
  readonly partnerId!: string;
  readonly partnerManagerUserId!: string | null;
  readonly partnerName!: string;
  readonly shippingOfficeId!: string;
  readonly vendorId!: string;
}
