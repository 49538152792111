export const framingRoutes = [
  {
    path: "/framing/mockup-sets",
    name: "FramingMockupSetsListPage",
    component: () => import("../framing/views/MockupSetsListPage.vue"),
    meta: {
      auth: ["FramingAdmin.Access"],
    },
  },
  {
    path: "/framing/passe-partouts",
    name: "FramingPassePartoutsPage",
    component: () => import("../framing/views/PassePartoutsPage.vue"),
    meta: {
      auth: {
        roles: ["FramingAdmin.Access"],
      },
    },
  },
  {
    path: "/framing/passe-partouts/:teamId",
    name: "FramingTeamPassePartoutsPage",
    component: () => import("../framing/views/TeamPassePartoutsPage.vue"),
    meta: {
      auth: {
        roles: ["FramingAdmin.Access"],
      },
    },
  },
];
