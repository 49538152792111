export const adyenMerchantRoutes = [
  {
    path: "/adyen-merchants",
    name: "AdyenMerchantListPage",
    component: () =>
      import("../adyenMerchants/views/AdyenMerchantListPage.vue"),
    meta: {
      auth: {
        roles: ["FinanceManager.Access"],
      },
    },
  },
];
