import axios, { type AxiosRequestConfig } from "axios";

import type {
  DateTime,
  Guid,
  PaginatedResult,
  UnknownObject,
} from "@/common/types";
import createInstance from "@/common/utils/createInstance";
import { MarketplaceEvent } from "@/events/types";
import type { DefaultProductProperties } from "@/products/types";

const catalogApiUrl = import.meta.env.VITE_CATALOG_API;

/*
 ** events search
 */

export const searchEvents = async (
  searchTerm: string,
  pageSize = 5,
  offset = 0,
) => {
  const axiosConfig: AxiosRequestConfig = {
    method: "get",
    url: `${catalogApiUrl}/admin/events/search`,
    params: {
      searchTerm,
      pageSize,
      offset,
    },
  };

  const response =
    await axios.request<PaginatedResult<UnknownObject>>(axiosConfig);

  const newEvents = response.data.results.map((event) =>
    createInstance(MarketplaceEvent, event),
  );

  return { ...response.data, results: newEvents };
};

/*
 ** events list with filtering
 */

export const getEventsList = async (
  searchTerm: string,
  pageSize = 5,
  offset = 0,
  filters?: {
    startAfter?: string;
    startBefore?: string;
    endAfter?: string;
    endBefore?: string;
    sortColumn?: string;
    sortDirection?: string;
    currentStatus?: string | null;
    categoryId?: string;
  },
) => {
  const axiosConfig: AxiosRequestConfig = {
    method: "get",
    url: `${catalogApiUrl}/admin/events`,
    params: {
      searchTerm,
      pageSize,
      offset,
      ...(filters || {}),
    },
  };

  const response =
    await axios.request<PaginatedResult<MarketplaceEvent>>(axiosConfig);

  response.data.results = response.data.results.map((event: unknown) =>
    createInstance(MarketplaceEvent, event as UnknownObject),
  );

  return response;
};

export const getEventsByTeamId = async (
  teamId: string,
  pageSize = 5,
  offset = 0,
) => {
  const axiosConfig: AxiosRequestConfig = {
    method: "get",
    url: `${catalogApiUrl}/events/teams/${teamId}`,
    params: {
      pageSize,
      offset,
    },
  };

  const response =
    await axios.request<PaginatedResult<MarketplaceEvent>>(axiosConfig);

  return response;
};

export type UpdateEventRequestDto = {
  name?: string;
  syncProductDates?: boolean;
  slug?: string;
  startDate?: DateTime;
  endDate?: DateTime;
  heroBannerOverlayColor?: string;
  defaultFrameMockupSetId?: string;
  defaultProductProperties?: Partial<DefaultProductProperties>;
  categoryIds?: Guid[];
  forceEventName: boolean;
  autoPublishNonParticipatingLineup: boolean;
  autoPublishParticipationChanges: boolean;
  autoPublishInitialLineup: boolean;
  excludeFromNowLive: boolean;
  excludeFromHomePage: boolean;
  logoImageUrl?: string;
  headerImageUrl?: string;
};

export const updateEventContract = async (
  eventId: Guid,
  request: {
    partnerId: Guid;
    vendorId: Guid | null;
    contractId: Guid | null;
    shippingOfficeId: Guid | null;
    regenerateCustomerInvoices: boolean | null;
    overrideEventPropertiesFromContract: boolean | null;
  },
) => {
  await axios.patch(
    `${catalogApiUrl}/admin/events/${eventId}/contracts`,
    request,
  );
};
