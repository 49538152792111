<script lang="ts" setup>
import type { FormKitFrameworkContext } from "@formkit/core";
import { ref, toRefs, watchEffect } from "vue";

const props = defineProps<{
  context: FormKitFrameworkContext;
}>();

const decimalPattern = /\d+\./;
const decimalNeedsRoundingPattern = /\d+\.\d{3,}/;

const isNumerical = (input: string) => !isNaN(Number(input)) && input !== "";

const shouldConvertNumberToDecimal = (input: string) =>
  isNumerical(input) && !decimalPattern.test(input);

const { context } = toRefs(props);

const displayValue = ref(
  shouldConvertNumberToDecimal(context.value._value)
    ? Number(context.value._value / 100).toFixed(2)
    : context.value._value?.toString(),
);

watchEffect(() => {
  if (!props.context.applyAutoFormat) return;

  const valueToSet = shouldConvertNumberToDecimal(context.value._value)
    ? Number(context.value._value / 100).toFixed(2)
    : context.value._value?.toString();

  // Only update and round if the unformatted values are actually different
  if (valueToSet?.split(".")[0] !== displayValue.value) {
    displayValue.value = valueToSet;
  }
});

const handleInput = (e: Event) => {
  const target = e.target as HTMLInputElement;
  let value = target.value.replace(",", ".") as string;
  let display = value;

  if (decimalNeedsRoundingPattern.test(value)) {
    // apply the original value to trigger a rerender when rounding.
    // this is due to lazy refs not updating when their value doesn't change.
    displayValue.value = display;

    // truncate to hundredths without rounding up.
    display = value = value.slice(0, value.indexOf(".") + 3);
  }

  if (isNumerical(value)) {
    value = Math.round(Number(value) * 100).toString();
  }

  displayValue.value = display;
  props.context.node.input(value);
};
</script>

<template>
  <div class="flex justify-start items-center w-full">
    <span v-if="context.currencySymbol">
      {{ context.currencySymbol }}
    </span>
    <input
      :class="context.classes.input"
      :disabled="!!context.disabled"
      :value="displayValue"
      number
      placeholder="0.00"
      type="text"
      v-bind="context.attrs"
      @input="handleInput"
    />
    <span
      v-if="context.percentage !== undefined"
      class="text-gray-400"
    >
      {{ `${(context.percentage as Number).toFixed(2)}%` }}
    </span>
  </div>
</template>
