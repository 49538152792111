<script setup lang="ts">
import type { FormKitFrameworkContext } from "@formkit/core";
import { computed } from "vue";

import { useGetAllLabelsQuery } from "../api/queries";

const props = defineProps<{
  context: FormKitFrameworkContext;
}>();

const { data: labels, isPending: isLabelsLoading } = useGetAllLabelsQuery();

const productLabelOptions = computed(() => {
  return labels.value?.map((label) => {
    return {
      value: label.id,
      label: label.text ?? "Unknown Label",
    };
  });
});

const updateSelectedOptions = (value: unknown) => {
  if (!value || !Array.isArray(value)) {
    return;
  }

  props.context.node.input(value);
};
</script>

<template>
  <ContentLoader
    v-if="isLabelsLoading"
    viewBox="0 0 200 25"
    class="grow"
  />
  <FormKit
    v-else-if="productLabelOptions?.length"
    data-cy="product-label-input"
    type="taglist"
    :options="productLabelOptions"
    :value="props.context.value"
    select-icon="down"
    close-icon="close"
    close-icon-class="h-2.5 text-white"
    tag-class="pr-2"
    input-class="ml-2"
    :ignore="true"
    :disable-drag-and-drop="true"
    @input="updateSelectedOptions"
  />
</template>
