<script setup lang="ts">
import { onMounted, ref } from "vue";

import CommandPalette from "../common/components/menu/commandPalette/CommandPalette.vue";
import MenuBar from "../common/components/menu/MenuBar.vue";
import ErrorBoundary from "../plugins/errors/components/ErrorBoundary.vue";

const isSearchModalOpen = ref(false);

onMounted(() => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  if (isSafari) {
    window.alert(
      "Safari is not officially supported. Please use a different browser otherwise you may encounter errors.",
    );
  }
});
</script>

<template>
  <MenuBar @open-search-click="isSearchModalOpen = true">
    <CommandPalette v-model:is-open="isSearchModalOpen" />
    <main class="main-content-container">
      <ErrorBoundary>
        <RouterView />
      </ErrorBoundary>
    </main>
  </MenuBar>
</template>

<style scoped lang="postcss">
.main-content-container {
  min-height: calc(100vh - 4rem); /* 4 rem = header height */
  @apply flex-1;
  @apply p-4;
  @apply overflow-x-hidden;
}
</style>
