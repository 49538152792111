import "reflect-metadata"; // required for class-transformer. see: https://www.npmjs.com/package/class-transformer#plaintoclassfromexist

import "./index.css";
import "./extraFormkitStyles.css";

import {
  defaultConfig as formkitDefaultConfig,
  plugin as formkitPlugin,
} from "@formkit/vue";
import * as Sentry from "@sentry/vue";
import {
  VueQueryPlugin,
  type VueQueryPluginOptions,
} from "@tanstack/vue-query";
import { createPinia } from "pinia";
import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import Toast, { type PluginOptions } from "vue-toastification";

// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import "@formkit/addons/css/multistep";

import ContentLoaderVue from "@/common/components/ContentLoaderVue.vue";
import RouterLinkVue from "@/common/components/RouterLinkVue.vue";
import storePopulation from "@/plugins/storePopulation";
import { beforeSend } from "@/sentry/sentryFilters";

import App from "./App.vue";
import FormKitForm from "./common/components/FormKitForm.vue";
import { localeMapper, messages } from "./messages";
import auth from "./plugins/auth";
import errors from "./plugins/errors";
import http from "./plugins/http";
import { router, routerPlugin } from "./router";
import formkitConfig from "../formkit.config";

const i18n = createI18n({
  legacy: false,
  locale: localeMapper(navigator.language),
  fallbackLocale: "en",
  messages: messages,
});
const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        staleTime: 1000 * 10, // 1000ms * 10
      },
    },
  },
};

const app = createApp(App);

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  tunnel: import.meta.env.VITE_SENTRY_TUNNEL,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  release: import.meta.env.VITE_SENTRY_RELEASE,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: [
        /^https:\/\/api\.mwsdevv\.com/,
        /^https:\/\/api\.mwsacc\.com/,
        /^https:\/\/api\.matchwornshirt\.com/,
        /^https:\/\/api\.matchwornshirt\.com/,
        /^\//,
      ],
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [/https:\/\/api.matchwornshirt.com\/.*/],
      networkRequestHeaders: ["X-Custom-Header"],
      networkResponseHeaders: ["X-Custom-Header"],
    }),
  ],
  tracesSampleRate: 0.1,
  logErrors: true,
  // Capture Replay for .1% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.001,
  replaysOnErrorSampleRate: 1.0,
  beforeSend,
});

// creating and setting the initial state for the store
const pinia = createPinia();
app.use(pinia);
app.use(storePopulation);

// setting the default headers for axios and preparing for vue-auth
app.use(http);
app.use(routerPlugin);
app.use(auth);
app.use(errors);
app.use(i18n);

const options: PluginOptions = {
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.34,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
};
app.use(Toast, options);

app.use(formkitPlugin, formkitDefaultConfig(formkitConfig));

app.use(VueQueryPlugin, vueQueryPluginOptions);

app.component("ContentLoader", ContentLoaderVue);
app.component("RouterLinkVue", RouterLinkVue);
app.component("FormKitForm", FormKitForm);

app.mount("#app");
