import type { ComponentPublicInstance } from "vue";

import { ApplicationError } from "./applicationError";

export class HttpError<TData = void> extends ApplicationError {
  public statusCode: number;
  public responseData!: TData;

  public constructor(
    sourceComponent: ComponentPublicInstance | null,
    sourceError: Error,
    statusCode: number,
    responseData?: TData,
  ) {
    super(sourceComponent, sourceError);
    this.statusCode = statusCode;
    if (responseData) {
      this.responseData = responseData;
    }
  }
}
