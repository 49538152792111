<script setup lang="ts">
import { ref } from "vue";

// components
import DesktopSidebar from "@/common/components/menu/DesktopSidebar.vue";
import MenuTopbar from "@/common/components/menu/MenuTopbar.vue";
import MobileMenu from "@/common/components/menu/MobileMenu.vue";

defineEmits<{
  (e: "open-search-click"): void;
}>();

const isSidebarOpen = ref(false);
const isDesktopSidebarOpen = ref(true);
</script>

<template>
  <div>
    <MobileMenu
      :is-sidebar-open="isSidebarOpen"
      @close-sidebar-click="isSidebarOpen = false"
    />

    <DesktopSidebar
      :is-desktop-sidebar-open="isDesktopSidebarOpen"
      @update:is-desktop-sidebar-open="isDesktopSidebarOpen = $event"
    />

    <div
      class="flex flex-col"
      :class="{
        'md:pl-60': isDesktopSidebarOpen,
        'md:pl-14': !isDesktopSidebarOpen,
      }"
    >
      <MenuTopbar
        @open-sidebar-click="isSidebarOpen = true"
        @open-search-click="$emit('open-search-click')"
      />
      <slot />
    </div>
  </div>
</template>
