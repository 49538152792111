import { useMutation } from "@tanstack/vue-query";
import axios, { type AxiosError } from "axios";

import type { UseQueryOptionsOmitQueryKey } from "../types";

const catalogApiUrl = import.meta.env.VITE_CATALOG_API;

export const useGenerateUploadUrlMutation = (
  onSuccess: (data: { key: string; preSignedUrl: string }) => void,
  onError: (error: AxiosError) => void,
) => {
  return useMutation({
    mutationFn: async (fileName: string) => {
      const response = await axios.post(`${catalogApiUrl}/admin/uploads`, {
        fileName,
      });

      return response.data;
    },
    onSuccess,
    onError,
  });
};

export const useUploadFileToS3Mutation = (
  options?: UseQueryOptionsOmitQueryKey<{ key: string; preSignedUrl: string }>,
) => {
  const mutationFn = async (file: File) => {
    const { data } = await axios.post<{ key: string; preSignedUrl: string }>(
      `${catalogApiUrl}/admin/uploads`,
      {
        fileName: file.name,
      },
    );

    // Delete auth header to not confuse AWS when uploading to S3.
    const instance = axios.create();
    delete instance.defaults.headers.common["Authorization"];

    await instance.put(data.preSignedUrl, file, {
      headers: {
        "Content-Type": file.type,
      },
    });

    return data;
  };

  return useMutation({ mutationFn, ...options });
};
