import type { AxiosError } from "axios";
import camelCase from "lodash/camelCase";

type Failure = {
  errorCode: string;
  errorMessage: string;
  propertyName: string;
};

type ErrorResponseData = {
  failures?: Failure[];
};

export type FormKitErrors = {
  formErrors: string[]; // errors to be shown in the general form level
  fieldErrors: Record<string, string[]>; // errors to be shown in the field level
};

/**
 * Get error message from error object from the backend.
 * If the `failures` array is empty, returns a "Something went wrong" message.
 * @param error
 * @returns {string[]}
 */
export function getErrorMessage(error: unknown) {
  const failureMessages: string[] = [];

  const axiosError = error as AxiosError;
  if (!axiosError) {
    return failureMessages;
  }

  const errorResponseData = axiosError?.response?.data as
    | ErrorResponseData
    | undefined;

  if (errorResponseData?.failures) {
    const failures = errorResponseData.failures;

    failures.forEach((failure) => {
      failureMessages.push(failure.errorMessage);
    });
  }

  if (failureMessages.length === 0) {
    failureMessages.push("Something went wrong");
  }

  return failureMessages;
}

/**
 * Get error messages from error object from the backend, mapped on property name. Will return properties in camelCase
 * @param error
 * @returns {Record<string, string[]>>}
 */
export function getErrorsPerProperty(error: unknown): Record<string, string[]> {
  const failureMessages = {} as Record<string, string[]>;

  const axiosError = error as AxiosError;
  if (!axiosError) {
    return failureMessages;
  }

  const errorResponseData = axiosError?.response?.data as
    | ErrorResponseData
    | undefined;

  if (errorResponseData?.failures) {
    const failures = errorResponseData.failures;

    failures.forEach((f) => {
      const propertyName = camelCase(f.propertyName);
      failureMessages[propertyName] = [
        ...(failureMessages[propertyName] ?? []),
        f.errorMessage,
      ];
    });
  }

  return failureMessages;
}

export function getFormKitErrors(error: unknown): FormKitErrors {
  return {
    formErrors: getErrorMessage(error),
    fieldErrors: getErrorsPerProperty(error),
  };
}
