export const framingOrderRoutes = [
  {
    path: "/framing/orders",
    name: "FramingOrderListPage",
    component: () => import("./views/OrderListPage.vue"),
    meta: {
      auth: ["FramingAdmin.Access", "FramingOrders.Read"],
    },
  },
  {
    path: "/framing/orders/:orderId",
    name: "OrderDetails",
    component: () => import("./views/OrderDetailsBase.vue"),
    meta: {
      auth: {
        roles: ["FramingAdmin.Access", "FramingOrders.Read"],
      },
    },
    redirect: { name: "OrderDetails.Details" },
    children: [
      {
        path: "",
        name: "OrderDetails.Details",
        component: () => import("./views/OrderDetails.vue"),
        meta: {
          auth: {
            roles: ["FramingAdmin.Access", "FramingOrders.Read"],
          },
        },
      },
      {
        path: "configurations",
        name: "OrderDetails.Configurations",
        component: () => import("./views/OrderDetailsConfigurationBase.vue"),
        meta: {
          auth: {
            roles: ["FramingAdmin.Access", "FramingOrders.Read"],
          },
        },
      },
      {
        path: "assets",
        name: "OrderDetails.Assets",
        component: () => import("./views/OrderDetailsAssets.vue"),
        meta: {
          auth: {
            roles: ["FramingAdmin.Access", "FramingOrders.Read"],
          },
        },
      },
    ],
  },
  {
    path: "/framing/orders/:orderId/configurations/:configurationId",
    name: "OrderConfigurationDetailPage",
    component: () => import("./views/OrderDetailsConfigurationPage.vue"),
    meta: {
      auth: {
        roles: ["FramingAdmin.Access", "FramingOrders.Read"],
      },
    },
  },
];
