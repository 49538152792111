import { onMounted, onUnmounted, ref } from "vue";

export enum BarcodeType {
  Serial = "serial",
}

export type SerialScanEvent = {
  chipId: string;
  externalReferenceId: string;
  orderId: string;
  barcodeType: BarcodeType;
};

export type BarcodeScan = {
  barcodeContent: string | null;
  barcodeType: BarcodeType | null;
};

export function useBarcodeScanning() {
  const scannedBarcode = ref<BarcodeScan | undefined>();
  let barcode = "";
  let reading = false;

  const keydownListenerCallback = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      if (barcode.length === 15 && barcode.charAt(0) === "0") {
        scannedBarcode.value = {
          barcodeContent: barcode,
          barcodeType: BarcodeType.Serial,
        };
        barcode = "";
      }
    } else if (e.key !== "Shift") {
      barcode += e.key;
    }

    if (!reading) {
      reading = true;
      setTimeout(() => {
        scannedBarcode.value = {
          barcodeContent: null,
          barcodeType: null,
        };
        barcode = "";
        reading = false;
      }, 400);
    }
  };

  onMounted(() => {
    document.addEventListener("keydown", keydownListenerCallback);
  });

  onUnmounted(() => {
    document.removeEventListener("keydown", keydownListenerCallback);
  });

  return scannedBarcode;
}
