import type { ColorMap } from "@/common/components/table/types";
import { UserGroup } from "@/users/types";

export const customerVerificationColorMap: ColorMap<UserGroup> = {
  [UserGroup.Temporary]: "yellow",
  [UserGroup.Anonymous]: "yellow",
  [UserGroup.Permanent]: "blue",
  [UserGroup.Verified]: "green",
  [UserGroup.Suspended]: "red",
  [UserGroup.BidBlocked]: "orange",
};
