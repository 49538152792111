export const sportsv2Routes = [
  {
    path: "/sports-v2",
    name: "SportsListPageV2",
    component: () => import("../sports-v2/views/SportsListPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
  {
    path: "/sports-v2/:sportId",
    name: "SportDetailsPageV2",
    component: () => import("../sports-v2/views/SportsDetailsPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
];
