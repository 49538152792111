import type { AxiosRequestConfig } from "axios";

const cognitoApiUrl = import.meta.env.VITE_COGNITO_API;

export const isRefreshRequest = (request: AxiosRequestConfig) => {
  return (
    request.url?.includes(`${cognitoApiUrl}/oauth2/token`) &&
    request.data?.grant_type === "refresh_token"
  );
};
