export const beneficiaryRoutes = [
  {
    path: "/beneficiaries",
    name: "BeneficiaryListPage",
    component: () => import("./views/BeneficiaryListPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
  {
    path: "/beneficiaries/:beneficiaryId",
    name: "BeneficiaryDetailsPage",
    component: () => import("./views/BeneficiaryDetailsPage.vue"),
    meta: {
      auth: {
        roles: ["Admin.Access"],
      },
    },
  },
];
