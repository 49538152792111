import type { FormKitNode } from "@formkit/core";
import camelCase from "lodash/camelCase";
import { useToast } from "vue-toastification";

import { useErrorComponent } from "./errorComponent";
import { ValidationError } from "../models";
import { createValidationErrorToastRenderFn } from "../utils";

export const useFormKitErrorHandler = (node: FormKitNode) => {
  const { registerErrorHandler } = useErrorComponent({
    handled: false,
    propagate: true,
  });
  const toast = useToast();

  const getFormErrors = (error: ValidationError) =>
    error.failures.map((failure) => failure.errorMessage);

  const getFieldErrors = (error: ValidationError) =>
    error.failures.reduce(
      (errors, error) => {
        // If the property name is not set, it's a form error by definition since
        // it can't be mapped to any field.
        if (!error.propertyName) {
          return errors;
        }

        if (!errors[error.propertyName]) {
          errors[camelCase(error.propertyName)] = error.errorMessage;
        } else {
          errors[error.propertyName] = error.errorMessage;
        }

        return errors;
      },
      {} as { [key: string]: string },
    );

  if (registerErrorHandler) {
    registerErrorHandler((error) => {
      if (error instanceof ValidationError) {
        if (node.context) {
          node.setErrors(getFormErrors(error), getFieldErrors(error));
        }

        // We show the toast notification because at the moment we have the concern that
        // the error message in the form doesn't pop out enough. The toast notification should
        // at least urge the user to start looking for the error until we have a better
        // form error
        toast.error(
          {
            render: createValidationErrorToastRenderFn(error, false),
          },
          { timeout: 10000 },
        );

        // NOTE: We're doing this because unfortunately when a javascript error is thrown in a submit
        // handler, formkit does not cleanly reset the form state, but remains "disabled". According to
        // the formkit team, this is intentional. Unfortunately, that means that it doesn't "natively"
        // work with our error boundary/global error handling system. This is our current workaround.
        node.store.remove("loading");
        node.props.disabled = false;

        return {
          handled: true,
          propagate: false,
        };
      }

      return true;
    });
  }
};
