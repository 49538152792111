import { useAuth } from "@websanova/vue-auth";

import PlainLayout from "@/layouts/PlainLayout.vue";

export const miscRoutes = [
  {
    path: "/",
    name: "HomePage",
    component: () => import("./views/HomePage.vue"),
    meta: {
      auth: ["Admin.Access"],
    },
  },
  {
    path: "/login",
    name: "LoginPage",
    component: () => import("./views/LoginPage.vue"),
    meta: { layout: PlainLayout },
    beforeEnter: () => {
      const auth = useAuth();

      if (auth?.check()) return { name: "HomePage" };
    },
  },
  {
    path: "/403",
    component: () => import("./views/ForbiddenPage.vue"),
  },
];
