export const permissionRoutes = [
  {
    path: "/permissions/roles",
    name: "RolesListPage",
    component: () => import("./views/RolesListPage.vue"),
    meta: {
      auth: {
        roles: ["Developer.Access"],
      },
    },
  },
  {
    path: "/permissions/roles/new",
    name: "RolesCreatePage",
    component: () => import("./views/RolesCreatePage.vue"),
    meta: {
      auth: {
        roles: ["Developer.Access"],
      },
    },
  },
  {
    path: "/permissions/roles/:roleId",
    name: "RolesUpdatePage",
    component: () => import("./views/RolesUpdatePage.vue"),
    meta: {
      auth: {
        roles: ["Developer.Access"],
      },
    },
  },
];
