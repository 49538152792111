const cognitoApiUrl = import.meta.env.VITE_COGNITO_API;
const cognitoClientId = import.meta.env.VITE_COGNITO_CLIENT_ID;
const cognitoIdentityProvider = import.meta.env.VITE_COGNITO_IDENTITY_PROVIDER;

/**
 * This provides the url and params for the cognito login request to be used by the vue-auth package.
 * For the available params, see: https://docs.aws.amazon.com/cognito/latest/developerguide/authorization-endpoint.html
 */
export default {
  url: `${cognitoApiUrl}/oauth2/authorize`,

  params: {
    response_type: "code",
    client_id: cognitoClientId,
    redirect_uri: `${window.location.origin}/login`,
    scope: "openid email",
    identity_provider: cognitoIdentityProvider,
  },
} as const;
